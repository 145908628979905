import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ApprovalsEntity } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';
import { ApprovalHelper } from '../../helpers/approval-helper';
import { LocalizationService } from '../../services/localization.service';
import { OfflineService } from '../../services/offline.service';

interface IMultipleApprovalsComponentInput {
    approvals: ApprovalsEntity[];
}

@Component({
    templateUrl: './multiple-approvals.component.html',
    styleUrls: ['./multiple-approvals.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MultipleApprovalsComponent {

    @Input()
    public modalInstance!: ModalInstance<IMultipleApprovalsComponentInput>;

    constructor(
        private readonly offline: OfflineService,
        private readonly localizationService: LocalizationService
    ) { }

    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public openApproval(index: number) {
        const approvalInfo = ApprovalHelper.getApprovalInfo(this.approvals[index]);
        this.offline.nativeLocalPathOpen(approvalInfo.url, approvalInfo.name, true, true);
    }

    public get approvals() {
        return this.modalInstance?.input?.approvals ?? [];
    }
}
