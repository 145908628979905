import { Injectable } from '@angular/core';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitPrecision, UnitServiceBase, UnitServiceInjected } from '@profis-engineering/pe-ui-common/services/unit.common';
import { PropertyMetaData } from '../entities/properties';
import { UIProperty } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { Unit } from '../entities/generated-modules/Hilti.PE.Units';

@Injectable({
    providedIn: 'root'
})
export class UnitService extends UnitServiceInjected {

    private baseService!: UnitServiceBase;

    public unitPrecision: UnitPrecision = {
        getPrecision: (unit: UnitType) => {
            return this.baseService.getPrecision(unit);
        },

        getPrecisionForProperty: (uiProperty: number, unit: UnitType) => {
            const additionalPrecision = (this as any)['getPrecision_' + PropertyMetaData.getById(uiProperty).name]?.(unit) ?? 0;
            return this.unitPrecision.getPrecision(unit) + additionalPrecision;
        }
    };

    public override setBaseService(baseService: UnitServiceBase): void {
        this.baseService = baseService;
        super.setBaseService(baseService);
        this.getPrecision = this._getPrecision;
    }


    public declare getPrecision: (unit: UnitType, uiProperty?: UIProperty, unitPrecision?: UnitPrecision) => number;

    private _getPrecision(unit: UnitType, uiProperty?: UIProperty) {
        return this.baseService.getPrecision(unit, uiProperty, this.unitPrecision);
    }


    public getPrecision_Loads_CW_DLSShear(unit: Unit) {
        switch (unit) {
            case Unit.mm:
                return 1;
            case Unit.cm:
                return 2;
            case Unit.inch:
                return 2;
            case Unit.ft:
                return 2;
            default:
                return 0;
        }
    }

    public getPrecision_Loads_CW_DLSTension(unit: Unit) {
        return this.getPrecision_Loads_CW_DLSShear(unit);
    }

    public getPrecision_BaseMaterial_CW_FcCyl(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
                return 1;
            case Unit.PSI:
                return 1;
            case Unit.KSI:
                return 1;
            default:
                return 0;
        }
    }

    public isMetric(unit: UnitType): boolean {
        return !(unit == UnitType.inch || unit == UnitType.ft || unit == UnitType.mi);
    }
}