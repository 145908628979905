import { Injectable, NgZone } from '@angular/core';
import { IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import {
    AppSettings, IDesignInfo, IDesignListInfo, IDesignManagement, IFavoritesInfo, IQuickStartApplication, IUserSettingsInfo, UserSettingsSection
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import {
    ISaveDesign, ISaveDesignResult
} from '@profis-engineering/pe-ui-common/entities/save-design';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { getModuleOrder } from '@profis-engineering/pe-ui-common/helpers/module-helper';

import { DesignTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { environment } from '../../environments/environmentCW';
import { DesignType } from '../entities/code-lists/design-type';
import { Constants } from '../entities/constants';
import { IDesignInfoCw } from '../entities/design-info-cw';
import { DesignType as DesignTypeEnum } from '../entities/enums/design-type';
import { IFixing } from '../entities/fixing';
import {
    NewDesignRequest
} from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Requests';
import { ApplicationTypes, DesignSubTypes } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { Unit } from '../entities/generated-modules/Hilti.PE.Units';
import { ApplicationSettingsCW, CustomUserSettingsCW, QuickStartSettingsCW } from '../entities/user-settings';
import { getSpriteAsIconStyle } from '../sprites';
import { AppSettingsService } from './app-settings.service';
import { CalculationService } from './calculation.service';
import { CodeListService } from './code-list.service';
import { DesignService } from './design.service';
import { DocumentService } from './document.service';
import { FavoritesService } from './favorites.service';
import { FeatureVisibilityService } from './feature-visibility.service';
import { LocalizationService } from './localization.service';
import { UserSettingsService } from './user-settings.service';

@Injectable({
    providedIn: 'root'
})
/**
 * Application provider service which exposes various data and functions for general (parent) module
 */
export class ApplicationProviderService {

    constructor(
        private readonly localization: LocalizationService,
        private readonly codeListService: CodeListService,
        private readonly userSettingsService: UserSettingsService,
        private readonly documentService: DocumentService,
        private readonly calculationService: CalculationService,
        private readonly appSettings: AppSettingsService,
        private readonly designService: DesignService,
        private readonly favoritesService: FavoritesService,
        private readonly featureVisibilityService: FeatureVisibilityService,
        private readonly ngZone: NgZone
    ) { }

    public getAppSettings(): AppSettings[] {
        return [
            {
                order: getModuleOrder(Constants.ModuleIndex, 0),
                name: 'app-settings-cw-module',
                collapsed: false,
                titleKey: 'Agito.Hilti.CW.ApplicationSettings.Menu.Title',
                title: this.localization.getString('Agito.Hilti.CW.ApplicationSettings.Menu.Title'),
                tooltipKey: 'Agito.Hilti.Profis3.Main.Region.ShowHide',
                sections: [
                    {
                        id: 'application-settings-cw-fixing',
                        titleKey: 'Agito.Hilti.CW.ApplicationSettings.Menu.AnchorChannelFacadeConfig',
                        title: this.localization.getString('Agito.Hilti.CW.ApplicationSettings.Menu.AnchorChannelFacadeConfig'),
                        componentTagName: 'cw-app-settings-fixing',

                        loadSettings: () => SafeFunctionInvokerHelper.safeInvoke(() => this.appSettings.loadSettings()),
                        handleRegionChange: (data: unknown, regionId: number) => this.appSettings.handleRegionChange(data as IFixing, regionId),
                        updateSettings: (data: unknown) => this.appSettings.updateSettings(data as IFixing)
                    }
                ]
            }
        ];
    }

    public getQuickStartApplications(): IQuickStartApplication[] {
        const isNewHomePageEnabled = this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');

        const facadeQuickStartApp: IQuickStartApplication = {
            order: getModuleOrder(Constants.ModuleIndex, 0),
            showInQuickStart: true,
            showInCollapsedState: true,

            designType: DesignTypeEnum.CurtainWall,

            idQuickStart: 'quick-start-button-cw',
            idCollapsed: 'quick-start-button-collapsed-cw',

            showNewLabel: () => true,
            newLabelTranslationKey: 'Common.QuickStartButtons.NewStatus',

            imageStyle: isNewHomePageEnabled ? getSpriteAsIconStyle('sprite-curtain-wall') : getSpriteAsIconStyle('sprite-cw-quick-start'),
            actionTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.Custom.Action',

            designTypeTranslationKey: 'Agito.Hilti.CW.QuickStart.DesignType.Facade',
            designTypeTranslation: this.localization.getString('Agito.Hilti.CW.QuickStart.DesignType.Facade'),
            collapsedTextTranslation: this.localization.getString('Agito.Hilti.CW.QuickStart.DesignType.Facade'),

            isEnabled: () => this.allowCwQuickStart,
            isDesignTypeDisabled: () => false,
            getButtonTooltip: () => undefined,
            createRegionDesignStandardApprovalNumber: () => {
                const designSettings = this.userSettingsService.getQuickStartSettingsData();
                return this.designService.createRegionDesignStandardApprovalNumber(designSettings.general_region.id, designSettings.calculation_designStandard?.id, undefined);
            },
            getNewDesignName: () => this.designService.getNewDesignName(DesignSubTypes.Facade),
            createNewDesign: saveDesign => this.createNewQuickStartDesign(saveDesign)
        };

        // 'Anchor channel' quick start application is based on 'Facade' quick start application with few exceptions
        const anchorChannelQuickStartApp: IQuickStartApplication = {
            ...facadeQuickStartApp,

            order: getModuleOrder(Constants.ModuleIndex, 1),
            idQuickStart: 'quick-start-button-anchor-channel',
            idCollapsed: 'quick-start-button-collapsed-anchor-channel',

            imageStyle: getSpriteAsIconStyle('sprite-anchor-channel'),

            designTypeTranslationKey: 'Agito.Hilti.CW.QuickStart.DesignType.AnchorChannel',
            designTypeTranslation: this.localization.getString('Agito.Hilti.CW.QuickStart.DesignType.AnchorChannel'),
            collapsedTextTranslation: this.localization.getString('Agito.Hilti.CW.QuickStart.DesignType.AnchorChannel'),

            isEnabled: () => environment.anchorChannelDesignSubTypeEnabled && facadeQuickStartApp.isEnabled(),
            getNewDesignName: () => this.designService.getNewDesignName(DesignSubTypes.AnchorChannel),
            createNewDesign: saveDesign => this.createNewQuickStartDesign(saveDesign, DesignSubTypes.AnchorChannel)
        };

        return [
            facadeQuickStartApp,
            anchorChannelQuickStartApp
        ];
    }

    public getDesignInfoList(): IDesignInfo[] {
        // List of designs which will be shown in 'Create new custom design' dialog and also when user edits the design settings
        return [
            this.getFacadeDesignInfo(),
            this.getAnchorChannelDesignInfo()
        ];
    }

    public getDesignInfo(designSubType?: DesignSubTypes, designName?: string): IDesignInfo {
        const designSubTypeTranslationsMap: Record<DesignSubTypes, () => IDesignInfo> = {
            [DesignSubTypes.None]: () => this.getFacadeDesignInfo(designName),
            [DesignSubTypes.Facade]: () => this.getFacadeDesignInfo(designName),
            [DesignSubTypes.AnchorChannel]: () => this.getAnchorChannelDesignInfo(designName)
        };

        return designSubTypeTranslationsMap[designSubType ?? DesignSubTypes.None]();
    }

    private getFacadeDesignInfo(designName?: string): IDesignInfo {
        const availableRegions = this.availableRegions;

        const facadeDesignInfo: IDesignInfoCw = {
            order: getModuleOrder(Constants.ModuleIndex, 0),
            id: 'add-edit-design-image-container-cw-facade-subtype',
            designTypeId: DesignTypeEnum.CurtainWall,
            designSubType: DesignSubTypes.Facade,
            title: this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesign.Main.QuickStart.Facade.DesignType'),
            titleTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesign.Main.QuickStart.Facade.DesignType',
            componentTagName: 'cw-add-edit-design',
            loadComponentImmidietly: true,
            mainTagName: 'cw-main',
            imageStyle: () => getSpriteAsIconStyle('sprite-cw-design-info'),
            availableRegions: availableRegions,

            isEnabled: () => environment.cwEnabled,
            isAvailable: regionId => availableRegions.includes(regionId),
            getDesignName: () => designName ?? this.designService.getNewDesignName(DesignSubTypes.Facade)
        };

        return facadeDesignInfo;
    }

    private getAnchorChannelDesignInfo(designName?: string): IDesignInfo {
        const defaultDesignInfo = this.getFacadeDesignInfo(designName);

        // 'Anchor channel' design info is based on 'Facade' design info with few exceptions
        const anchorChannelDesignInfo: IDesignInfoCw = {
            ...defaultDesignInfo,

            designSubType: DesignSubTypes.AnchorChannel,
            order: getModuleOrder(Constants.ModuleIndex, 1),
            id: 'add-edit-design-image-container-cw-anchor-channel-subtype-subtype',
            title: this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesign.Main.QuickStart.AnchorChannel.DesignType'),
            titleTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesign.Main.QuickStart.AnchorChannel.DesignType',
            imageStyle: () => getSpriteAsIconStyle('sprite-anchor-channel-design-info'),

            isEnabled: regionId => defaultDesignInfo.isEnabled(regionId),
            isAvailable: regionId => environment.anchorChannelDesignSubTypeEnabled && defaultDesignInfo.isAvailable(regionId),
            getDesignName: () => designName ?? this.designService.getNewDesignName(DesignSubTypes.AnchorChannel)
        };

        return anchorChannelDesignInfo;
    }

    public getDesignListInfo(): IDesignListInfo[] {
        // Need to return only one instance of of it, we have only one design type for two tiles
        return [
            {
                order: getModuleOrder(Constants.ModuleIndex, 0),
                designTypeId: DesignTypeEnum.CurtainWall,
                designTypeName: this.localization.getString('Agito.Hilti.Profis3.CodeList.DesignTypeEntity.Facade'),
                designTypeImage: getSpriteAsIconStyle('sprite-model3d-cw'),

                isEnabled: () => environment.cwEnabled,
                checkDesign: (design: IBaseDesign) => {
                    return this.ngZone.run(() => this.calculationService.openFromDocumentDesign(design, false));
                },
                openFromDocumentDesign: (design: IBaseDesign) => {
                    return this.ngZone.run(() => this.calculationService.openFromDocumentDesign(design));
                },
                copyDesign: (designId: string, designName: string, projectId: string) => {
                    return this.ngZone.run(() => this.calculationService.copyFromDocumentDesign(designId, designName, projectId));
                },
                openDesignSettings: (designId: string, designName: string, regionId: number, onDesignSettingsClosed?: () => void) => {
                    return this.ngZone.run(() => this.calculationService.openDesignSettings(designId, regionId, this, designName, onDesignSettingsClosed));
                },
                toDisplayDesign: (design: IDesignListItem, getDesignThumbnail?: (designId: string) => string) => this.designService.toDisplayDesign(design, 'sprite-model3d-cw', getDesignThumbnail),
                toDisplayDesignTemplate: (template: DesignTemplateEntity, getDesignThumbnail?: (templateId: string) => string) => this.designService.toDisplayDesignTemplate(template, 'sprite-model3d-cw', getDesignThumbnail),
                getDesign: async (designId: string) => {
                    const documentDesign = this.documentService.findDesignById(designId);
                    return this.ngZone.run(async () => (await this.calculationService.openFromDocumentDesign(documentDesign, false)).design);
                },
                openTemplate: (templateId: string) => {
                    return this.ngZone.run(() => this.calculationService.openTemplate(templateId));
                },
                openTemplateSettings: (templateId: string, onDesignSettingsClosed?: () => void) => {
                    return this.ngZone.run(() => this.calculationService.openTemplateSettings(templateId, this, onDesignSettingsClosed));
                },
                newDesignFromTemplate: (designId: string, projectId?: string, designName?: string) => {
                    return this.ngZone.run(() => this.calculationService.newDesignFromTemplate(designId, projectId, designName));
                }
            }
        ];
    }

    public getDesignManagementInfo(): IDesignManagement[] {
        const designManagement = {
            designTypeId: DesignTypeEnum.CurtainWall,

            openDesignExclusive: (design: IBaseDesign) => {
                return this.documentService.openDesignExclusive(design);
            }
        } as IDesignManagement;

        return [ designManagement ];
    }

    public get getDesignTypes() {
        const designType = DesignType.fromService({
            id: 107,
            displayKey: 'Facade',
            regions: []
        });

        designType.name = this.localization.getString(Constants.FacadeAndAnchorChannelHomePageTranslationKey);

        return [designType];
    }

    public getUserSettingsInfo(): IUserSettingsInfo {
        return {
            userSettingsKey: 'cw',
            userSettings: new CustomUserSettingsCW() as unknown as UserSettingsSection,
            quickStart: new QuickStartSettingsCW() as unknown as UserSettingsSection,
            applicationSettings: new ApplicationSettingsCW() as unknown as UserSettingsSection
        } as any as IUserSettingsInfo;
    }

    public getFavoritesInfo(): IFavoritesInfo {
        return {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            getFavoritesType: (menuType: MenuType, designType: number, designStandard?: number, connectionType?: number) => this.favoritesService.getFavoritesType(menuType, designType),
            getMenuRegionIdFromFavorites: (id: string, designType: number) => this.favoritesService.getMenuRegionIdFavorites(id, designType)
        };
    }

    private async createNewQuickStartDesign(saveDesign: ISaveDesign, designSubType?: DesignSubTypes): Promise<ISaveDesignResult> {
        const quickStartSettingsCw = this.userSettingsService.getQuickStartSettingsData();

        const regionId = quickStartSettingsCw.general_region?.id != null && quickStartSettingsCw.general_region.id > 0
            ? quickStartSettingsCw.general_region.id
            : this.userSettingsService.settings.application.general.regionId.value;

        const designRequest = {
            regionId: regionId,
            designStandard: quickStartSettingsCw.calculation_designStandard?.id,
            designMethodGroup: quickStartSettingsCw.calculation_designMethod?.id,
            applicationType: ApplicationTypes.None,
            designSubType: designSubType ?? DesignSubTypes.Facade,
            projectId: saveDesign.projectId,
            projectName: saveDesign.projectName,
            designName: saveDesign.designName,
            language: this.userSettingsService.getLanguage().culture,
            numberDecimalSeparator: this.userSettingsService.getDecimalSeparator().character,
            numberThousandsSeparator: this.userSettingsService.getThousandsSeparator().character,
            unitLength: quickStartSettingsCw.units_length?.id ?? Unit.mm,
            unitArea: quickStartSettingsCw.units_area?.id ?? Unit.mm2,
            unitStress: quickStartSettingsCw.units_stress?.id ?? Unit.N_mm,
            unitForce: quickStartSettingsCw.units_force?.id ?? Unit.kN,
            unitMoment: quickStartSettingsCw.units_moment?.id ?? Unit.kNm,
            unitTemperature: quickStartSettingsCw.units_temperature?.id ?? Unit.C,
            unitForcePerLength: quickStartSettingsCw.units_force_per_length?.id ?? Unit.kN_m,
            unitMomentPerLength: quickStartSettingsCw.units_moment_per_length?.id ?? Unit.kNm_m,
            unitDensity: quickStartSettingsCw.units_density?.id ?? Unit.kg_m3,
            basePlateFactor: quickStartSettingsCw.basePlateFactor,
            safetyFactorPermLoad: quickStartSettingsCw.safetyFactorPermLoad,
            safetyFactorVarLoad: quickStartSettingsCw.safetyFactorVarLoad,
            minAnchorProfileDist: quickStartSettingsCw.minAnchorProfileDist,
            minConcreteCover: quickStartSettingsCw.minConcreteCover,
            concreteSafetyFactorGammaC: quickStartSettingsCw.concreteSafetyFactorGammaC,
            forceFreeLicense: this.userSettingsService.settings.application.general.forceFreeLicense.value ?? false
        } as NewDesignRequest;

        const calcResult = await this.ngZone.run(() => this.calculationService.createNewDesign(designRequest));

        return {
            designId: calcResult?.design.id,
            path: `${Constants.DesignPath}/`,
            design: calcResult?.design,
            success: true
        };
    }

    private get availableRegions() {
        return this.codeListService.designType?.regions ?? [];
    }

    private get allowCwQuickStart(): boolean {
        if (!environment.cwEnabled) {
            return false;
        }

        let regionId = this.userSettingsService.settings.quickStart.curtainWall.generalRegionId.value;
        if (regionId == null || regionId == SpecialRegion.Default) {
            regionId = this.userSettingsService.settings.application.general.regionId.value;
        }

        return this.availableRegions.some(r => r == regionId);
    }
}
