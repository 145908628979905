import debounce from 'lodash-es/debounce';
import { Subscription } from 'rxjs';

import {
    AfterViewInit,
    ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import { Change, Update } from '@profis-engineering/gl-model/base-update';
import { IModel, LoadsVisibilityInfo } from '@profis-engineering/gl-model/gl-model';
import { Context3dKey } from '@profis-engineering/pe-ui-common/entities/context-3d';
import {
    Design as DesignCommon, DesignEvent, StateChange
} from '@profis-engineering/pe-ui-common/entities/design';
import { IDesignSectionComponent } from '@profis-engineering/pe-ui-common/entities/design-section';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { LoadsLegendType } from '@profis-engineering/pe-ui-common/components/loads-legend/loads-legend.common';
import { IMainMenuComponent, IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import {
    INotificationsComponentInput, INotificationScopeCheck, NotificationType
} from '@profis-engineering/pe-ui-common/entities/notifications';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import { ProjectAndDesignView } from '@profis-engineering/pe-ui-common/services/user.common';
import Sortable, { Options } from 'sortablejs';
import { Command, commandFromService } from '../../entities/command';
import { Design, IDesignState } from '../../entities/design';
import { DesignType } from '../../entities/enums/design-type';
import { NumericalParameter, ScopeCheckResultItemEntity, TranslationFormat, TranslationParameter } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities';
import { Point3D } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design.Geometry';
import { UtilizationLoadForceEntity } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.DesignReportData';
import { ApplicationTypes, ScopeCheckFlags, TranslationParameterTypes } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { ScopeCheckButtonParameter, UIPropertyValue } from '../../entities/generated-modules/Hilti.PE.ScopeChecks.CW.Entities';
import { DetailedScopeCheckInfoPopup } from '../../entities/generated-modules/Hilti.PE.ScopeChecks.CW.Enums';
import { PropertyMetaData } from '../../entities/properties';
import { IAnchoringSystem, IAnchoringSystemGeometry, IBasePlateSystem, IModelCW } from '../../gl-model/base-component';
import { GlModelProps, IGlModelComponent, IScreenShotSettingsCW } from '../../gl-model/gl-model';
import { ApprovalHelper } from '../../helpers/approval-helper';
import { DesignHelper } from '../../helpers/design-helper';
import { getDesignTitle } from '../../helpers/design-title-helper';
import { fromCwUnitGroup } from '../../helpers/unit-helper';
import { ApplicationProviderService } from '../../services/application-provider.service';
import { CalculationService } from '../../services/calculation.service';
import { DesignTemplateService } from '../../services/design-template.service';
import { DesignService } from '../../services/design.service';
import { DocumentService } from '../../services/document.service';
import { FavoritesService } from '../../services/favorites.service';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { LoggerService } from '../../services/logger.service';
import { MenuService } from '../../services/menu.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { RegionOrderService } from '../../services/region-order.service';
import { RoutingService } from '../../services/routing.service';
import { ScreenshotService } from '../../services/screenshot.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';
import { IAnchorChannel, IRebarChannel } from '../gl-model/components/anchor-channel';
import { IBaseMaterial } from '../gl-model/components/base-material';
import { IBolt } from '../gl-model/components/bolt';
import { ILoadCombinationValues } from '../gl-model/components/loads-manager';
import { IPiBracket, IPlateBracket, IPlateStandoff, ISlottedHole } from '../gl-model/components/plate-bracket';
import { IRebarPlate } from '../gl-model/components/rebar-plate';
import { registerMainMenuCustomStyles } from '../main-menu/MainMenuHelper';
import { UtilizationType } from '../utilizations/utilizations.component';

interface IMainMenuComponentElement extends IMainMenuComponent, HTMLElement { }

export enum DisplayOptionEditor {
    Editor3D,
    Editor2D,
    Both
}

export enum Controls2dEditor { }

const enum FloatingInfo {
    ProductDropDown = 1
}

const setTimeoutPromise = (handler: () => void, timeout?: number) => new Promise<void>((resolve, reject) => setTimeout(() => {
    try {
        handler();
        resolve();
    }
    catch (error) {
        reject(error);
    }
}, timeout));


export enum CollapsingControls {
    // pe-ui-CW controls
    AnchorLoads = 1000,
    Utilizations = 1001,
    BoltLoads = 1002,
    NotificationsDesign = 1003,
    ConcreteCompressionForce = 1004,
    ValidationError = 1005,
    ToleranceTable = 1006
}

export const enum ViewType {
    View2d,
    View3d
}

@Component({
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('glModelRef')
    public glModelComponent!: ElementRef<IGlModelComponent>;

    @ViewChild('designSectionRef')
    public designSectionComponent!: ElementRef<IDesignSectionComponent>;

    @ViewChild('mainMenuRef')
    public mainMenuComponentElementRef!: ElementRef<IMainMenuComponentElement>;

    public glModel!: Pick<GlModelProps,
        'continuousRender' |
        'model' |
        'onFontsLoaded' |
        'onZoom' |
        'onSelectTab' |
        'onPositionsChanged' |
        'onDraggingSelectionChanged'
    >;

    public Context3dKeyMain = Context3dKey.Main;

    public utilizationTypeEnum = {
        Default: UtilizationType.Default,
        ConcreteCompressionForce: UtilizationType.ConcreteCompressionForce,
    };

    public modelViewZoom = 0;
    public zoomPercentage = '0%';

    public CollapsingControls = CollapsingControls;
    public ViewType: Record<keyof typeof ViewType, ViewType> = {
        View2d: ViewType.View2d,
        View3d: ViewType.View3d
    };
    public FloatingInfo: Record<keyof typeof FloatingInfo, FloatingInfo> = {
        ProductDropDown: FloatingInfo.ProductDropDown
    };

    public design?: Design;

    public draggingSelectionOptions!: string;
    public rightSideLoaded!: boolean;
    public hideLeftMenu = false;
    public hideRightMenu = false;
    public userLogout = false;

    public sortableMenu3DRightOptions!: Options;

    public floatingInfo?: FloatingInfo;

    public notificationComponentInputs!: INotificationsComponentInput;

    public view: ViewType = ViewType.View3d;

    private localizationChangeSubscription!: Subscription;

    private undoSubscription: Subscription | undefined;

    constructor(
        public routingService: RoutingService,
        public localizationService: LocalizationService,
        public userSettingsService: UserSettingsService,
        public offlineService: OfflineService,
        public modalService: ModalService,
        public calculationService: CalculationService,
        private readonly userService: UserService,
        private readonly featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private readonly featureVisibilityService: FeatureVisibilityService,
        private readonly menuService: MenuService,
        private readonly unitService: UnitService,
        private readonly documentService: DocumentService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly numberService: NumberService,
        private readonly applicationProviderService: ApplicationProviderService,
        private readonly translationFormatService: TranslationFormatService,
        private readonly favoritesService: FavoritesService,
        private readonly regionOrderService: RegionOrderService,
        private readonly designTemplateService: DesignTemplateService,
        private readonly designService: DesignService,
        private readonly ngZone: NgZone,
        private readonly screenshotService: ScreenshotService,
        private readonly logger: LoggerService
    ) {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-undo',
            'sprite-redo',
            'sprite-arrow-left-medium',
            'sprite-arrow-right-medium',
            'sprite-view',
            'sprite-center',
            'sprite-search'
        );
    }

    public get isNewHomePage() {
        return this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
    }

    public get zoomUnit() {
        return UnitType.percent;
    }

    public get selectedMenu(): IMenu {
        return this.mainMenuComponent?.getSelectedMenu() ?? {};
    }

    public get mainMenuComponent() {
        return this.mainMenuComponentElementRef?.nativeElement;
    }

    public get language() {
        return this.userSettingsService.getLanguage();
    }

    public get designRegionId() {
        return this.design?.region?.id;
    }

    public get mostUnfavorableTolerance(): Point3D {
        return {
            x: this.design?.designData?.reportData?.mostUnfavorableTolerance ?? Number.NaN,
            y: this.design?.designData?.reportData?.mostUnfavorableToleranceY ?? Number.NaN,
            z: this.design?.designData?.reportData?.mostUnfavorableToleranceZ ?? Number.NaN
        };
    }

    public get anchorForceList(): UtilizationLoadForceEntity[] {
        return this.design?.designData?.reportData?.anchorForces ?? [];
    }

    public get boltForceList(): UtilizationLoadForceEntity[] {
        return this.design?.designData?.reportData?.boltForces ?? [];
    }

    public get hasExtendedWidth() {
        if (this.selectedMenu?.tabs == undefined)
            return false;

        const selectedTab = this.selectedMenu?.tabs[this.selectedMenu.selectedTab];
        const canExtend = selectedTab?.width == NavigationTabWidth.Extended ?? false;
        return canExtend && !this.hideLeftMenu;
    }

    public get isLoading() {
        return this.design?.loading;
    }

    public get title() {
        if (this.design == null)
            return;

        return getDesignTitle(this.design, this.localizationService, this.numberService);
    }

    public get undoRedoHidden() {
        return this.featuresVisibilityInfoService.isHidden(Feature.Design_UndoRedo, this.designRegionId);
    }

    public get undoRedoDisabled() {
        return this.featuresVisibilityInfoService.isDisabled(Feature.Design_UndoRedo, this.designRegionId);
    }

    public get undoTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_UndoRedo) ||
            this.localizationService.getString('Agito.Hilti.Profis3.Main.Undo');
    }

    public get redoTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_UndoRedo) ||
            this.localizationService.getString('Agito.Hilti.Profis3.Main.Redo');
    }

    public get glModelComponentRef(): IGlModelComponent {
        return this.glModelComponent.nativeElement;
    }

    public get loadsLegendType(): LoadsLegendType {
        return this.design?.loadsLegendType ?? LoadsLegendType.None;
    }

    public ngOnInit(): void {
        this.design = this.userService.design;

        this.menuService.initialize();

        this.glModelComponentUpdate = this.glModelComponentUpdate.bind(this);
        this.resize3d = this.resize3d.bind(this);
        this.tabSelected = this.tabSelected.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onCloseEventTrack = this.onCloseEventTrack.bind(this);
        this.openDesignSettings = this.openDesignSettings.bind(this);
        this.openSaveAsTemplate = this.openSaveAsTemplate.bind(this);
        this.beforeLogout = this.beforeLogout.bind(this);

        this.TrackDataOnTabClose();

        // add body class
        document.body.classList.add('main-view-body');

        // show the page and then load controls after GLModel asynchronously
        setTimeoutPromise(() => this.initGLModel())
            .then(() => setTimeoutPromise(() => this.initMenu3d()))
            .then(() => setTimeoutPromise(() => this.initRightSide()))
            .then(() => setTimeoutPromise(() => this.screenshotService.setGlModelInstance(this.glModelComponentRef)))
            .catch(err => {
                if (err instanceof Error) {
                    this.logger.log(err.message, LogType.error);
                }
            });

        this.saveUserSettings = debounce(this.saveUserSettings.bind(this), 2000);

        // attach events to document
        document.addEventListener('keydown', this.onKeyDown, false);

        this.zoomPercentage = this.unitService.formatInternalValueAsDefault(0, UnitGroup.Percentage);

        this.localizationChangeSubscription = this.localizationService.localizationChange.subscribe(() => {
            const selected = this.selectedMenu?.selectedTab;

            this.initMenu3d();

            this.selectTabById(selected);
        });

        this.undoSubscription = this.calculationService.undoObservable$.subscribe(() => {
            this.setNotificationComponentInputs();
        });

        /* eslint-disable @typescript-eslint/no-unused-vars */
        this.sortableMenu3DRightOptions = {
            handle: '.drag-handle-static',
            store: {
                get: (sortable) => {
                    return [];
                },
                set: (sortable) => {
                    this.regionOrderService.update(sortable.toArray(), MenuType.Menu3DRight);
                }
            },
            onSort: (event) => undefined
        };
        /* eslint-enable @typescript-eslint/no-unused-vars */

        this.setNotificationComponentInputs();
    }

    ngAfterViewInit(): void {
        const mainMenuShadowRoot = this.mainMenuComponent?.shadowRoot;
        if (mainMenuShadowRoot == null)
            return;

        registerMainMenuCustomStyles(mainMenuShadowRoot);
    }

    public ngOnDestroy(): void {
        this.localizationChangeSubscription.unsubscribe();

        if (this.design != null) {
            this.design.off(DesignEvent.stateChanged, this.onStateChanged);
            this.design.off(DesignEvent.beforeCalculate, this.onBeforeCalculate);
            this.design.off(DesignEvent.calculate, this.onCalculate);
            this.publish();

            // angular bug: template render is still called multiple times after ngOnDestroy for some reason
            // this causes errors since this.design is already null
            setTimeout(() => {
                this.design = undefined as any;
            }, 100);
        }

        // remove body class
        document.body.classList.remove('main-view-body');

        // remove document events
        document.removeEventListener('keydown', this.onKeyDown, false);
        window.removeEventListener('beforeunload', this.onCloseEventTrack, false);

        if (this.undoSubscription) {
            this.undoSubscription.unsubscribe();
        }
    }

    private publish() {
        if (this.design == null)
            return;

        this.design.processDesignClose(false);
        return this.documentService.publish(this.design.id);
    }

    public glModelComponentUpdate(model: IModelCW, replace?: boolean) {
        return this.glModelComponentRef.update(model, replace);
    }

    public TrackDataOnTabClose() {
        window.addEventListener('beforeunload', this.onCloseEventTrack, false);
    }

    public resize3d() {
        if (this.glModelComponentRef != null) {
            this.glModelComponentRef.resizeNextFrame();
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public async beforeLogout() {
        if (this.design != null && !this.design.isTemplate) {
            this.userLogout = true;
            await this.design.processDesignClose(true);
        }
    }

    public async openSupportOverride() {
        this.modalService.openSupport({}, this.design?.projectDesign);
    }

    public setCursor(cursor: 'move' | '') {
        (document.getElementsByClassName('main-content-center-right')[0] as HTMLElement).style.cursor = cursor;
    }

    public openDesignSettings() {
        if (this.design == null)
            return;

        this.modalService.openAddEditDesignFromModule({
            design: {
                id: this.design.id,
                name: this.design.designName,
                projectId: this.design.projectId,
                projectName: this.design.projectName,
                region: this.design.region,
                displayDesignType: this.design.isTemplate ? DisplayDesignType.template : DisplayDesignType.design,
                designType: DesignType.CurtainWall
            },
            selectedModuleDesignInfo: this.applicationProviderService.getDesignInfo(this.design?.designSubType, this.design?.designName),
            addEditType: AddEditType.edit,
            onDesignEdited: (_, project) => {
                if (this.design == null)
                    return;

                this.design.projectId = project.id ?? this.design.projectId;
                this.design.projectName = project.getDisplayName(this.localizationService) ?? this.design.projectName;
                this.userService.changeDesign(project as Project, this.design);
                this.ngZone.run(() => {
                    if (this.design) {
                        this.calculationService.calculateAsync(this.design, undefined, { suppressLoadingFlag: true });
                    }
                });
            }
        });
    }

    public async openSaveAsTemplate() {
        if (this.design == null)
            return;

        this.modalService.openSaveAsTemplate({
            designTemplateDocument: this.designService.designToTemplateDocument(this.design),
            thumbnailId: this.design.id,
            onTemplateSaved: this.onTemplateSaved.bind(this)
        });
    }

    private onTemplateSaved() {
        this.userService.projectAndDesignView = ProjectAndDesignView.templates;

        this.routingService.navigateToUrl(UrlPath.projectAndDesign);
    }

    public resetCamera() {
        this.glModelComponentRef.resetCamera();
    }

    public updateGlModel(updates: IModelCW) {
        this.glModelComponentRef.update(updates);
    }

    public zoomToFit() {
        const extraInfo = {
            imgHeight: 0,
            imgWidth: 0,
            zoomed: false,
            preview: true,
            loadsVisibilityInfo: {
                preview: true,
                modifyLoads: false,
            } as LoadsVisibilityInfo
        };

        return this.glModelComponentRef.zoomToFit(extraInfo);
    }

    public zoomPercentageChange(value: number) {
        // The value is changed inside the same cycle so change detection
        // needs to be run again before the new change
        this.changeDetector.detectChanges();
        this.glModelComponentRef.cameraZoom(value);
        this.zoomPercentage = this.unitService.formatInternalValueAsDefault(this.modelViewZoom < 0 ? 0 : this.modelViewZoom, UnitGroup.Percentage);
    }

    public onPlusClick() {
        this.glModelComponentRef.cameraZoomIn();
    }

    public onMinusClick() {
        this.glModelComponentRef.cameraZoomOut();
    }

    public toggleLeftMenu() {
        this.hideLeftMenu = !this.hideLeftMenu;

        this.resize3dAfterUI();
    }

    public toggleRightMenu() {
        this.hideRightMenu = !this.hideRightMenu;

        this.resize3dAfterUI();
    }

    public undo() {
        if (!this.canUndo()) {
            return;
        }
        else {
            this.calculationService.undo(this.design);
        }

        this.setNotificationComponentInputs();
    }

    public redo() {
        if (!this.canRedo()) {
            return;
        }
        else {
            this.calculationService.redo(this.design);
        }

        this.setNotificationComponentInputs();
    }

    public canUndo() {
        return this.design?.canUndo && !this.undoRedoDisabled && !this.design.isReadOnlyDesignMode;
    }

    public canRedo() {
        return this.design?.canRedo && !this.undoRedoDisabled && !this.design.isReadOnlyDesignMode;
    }

    public floatingInfoChange(floatingInfo: FloatingInfo, collapsed: boolean) {
        if (collapsed && this.floatingInfo == floatingInfo) {
            // everything is collapsed
            this.floatingInfo = undefined;
        }
        else if (!collapsed) {
            // open this specific floating info
            this.floatingInfo = floatingInfo;
        }
    }

    private onCloseEventTrack() {
        if (!this.userLogout) {
            this.processDesignBrowserUnload();
        }
    }

    private async processDesignBrowserUnload() {
        if (this.design != null) {
            await this.design.processDesignBrowserUnload();
        }
    }

    private selectTabById(tab: string) {
        this.mainMenuComponent?.selectTab(tab);
    }

    private saveUserSettings() {
        this.userSettingsService.save();
    }

    private onBeforeCalculate(design: DesignCommon, changes: Change[]) {
        if (changes != null && changes.length > 0) {
            this.updateGLModel(design, changes, Update.Client, undefined, true);
        }
    }

    private onCalculate(design: DesignCommon, changes: Change[]) {
        this.updateGLModel(design, changes, Update.Client, undefined, false, true);
        //this.glModelComponentRef.update(this.glModel.model!);

        this.setNotificationComponentInputs();
    }

    private updateGLModel(design: DesignCommon, changes: Change[], update: Update, model?: IModel, beforeCalculate = false, updateDesignScreenshot = false) {
        this.glModelComponentRef.propertyValueChanged(changes, design as any, update, model);

        // updateGLModel is called multiple times so we only update the screenshot when update != Controls.GLUpdate.Update.server
        if (!beforeCalculate && update != Update.Server && updateDesignScreenshot) {
            setTimeout(() => {
                this.createDesignScreenshot();
            });
        }
    }

    private createDesignScreenshot() {
        if (this.design == null) {
            return;
        }

        const isTemplate = this.design.isTemplate;
        const id = (isTemplate ? this.design.templateId : this.design.id) as string;
        const isCreateTemplate = this.userService.isCreateTemplate;
        const updateThumbnail = isTemplate ? this.designTemplateService.updateDesignThumbnailImage : this.documentService.updateDesignThumbnailImage;

        this.glModelComponentUpdate(this.glModelComponentRef.getModel() as IModelCW, false).then(() => {
            this.glModelComponentRef.createDesignScreenshot({ isThumbnail: true, imgHeight: 145, imgWidth: 145, zoomed: false, preview: true } as IScreenShotSettingsCW)
                .then(img => {
                    updateThumbnail(id, img, false).then(async () => {
                        if (isCreateTemplate) {
                            await this.createNewTemplate();
                        }
                    });
                });
        });
    }

    private async createNewTemplate() {
        if (this.design == null) {
            return;
        }

        const template = {
            designTemplateDocument: this.designService.designToTemplateDocument(this.design),
            thumbnailId: this.design.id,
        };
        template.designTemplateDocument.templateName = this.design.templateName;
        template.designTemplateDocument.templateFolderId = this.design.templateFolderId;
        //save new  template
        this.designTemplateService.create(template.designTemplateDocument, template.thumbnailId).then((response) => {
            //enable editing of template file
            this.userService.design.templateId = response;
            this.userService.design.isTemplate = true;
            this.userService.setIsCreateTemplate(false);
        });
    }

    private onStateChanged(design: DesignCommon, state: IDesignState, oldState: IDesignState, stateChange: StateChange) {
        let needMenuUpdate = false;

        if (DesignHelper.hasProductFasteningTechologyStateChanged(oldState, state)) {
            needMenuUpdate = true;
        }

        if (oldState.model[PropertyMetaData.Option_CW_ApplicationType.id] as number ^ state.model[PropertyMetaData.Option_CW_ApplicationType.id] as number) {
            needMenuUpdate = true;
        }

        if (needMenuUpdate) {
            this.initMenu3d(this.selectedMenu?.selectedTab);
        }

        if (stateChange == StateChange.server) {
            // call all update methods
            this.updateGLModel(this.design as Design, null as unknown as Change[], Update.Server);
        }
        else {
            // other state change
            if (state === oldState) {
                this.updateGLModel(this.design as Design, null as unknown as Change[], Update.ServerAndClient);
            }
            else {
                const changes: Change[] = [];
                for (const key in state.model) {
                    const index = Number(key);
                    changes.push({
                        name: key,
                        oldValue: oldState.model[index],
                        newValue: state.model[index]
                    });
                }

                this.updateGLModel(this.design as Design, changes, Update.ServerAndClient, undefined, false, true);
            }
        }

        this.resize3dAfterUI();
    }

    private getAnchoringSystemById(model: IModelCW, anchoringSystemId: string): IAnchoringSystem {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return model.anchoringSystems.find(x => x.id == anchoringSystemId)!;
    }

    private isAnchoringSystemSelected(anchoringSystemId: string): boolean {
        return anchoringSystemId == this.design?.selectedAnchoringSystemId;
    }

    private getBasePlateSystemById(model: IModelCW, basePlateSystemId: string, anchoringSystemId: string): IBasePlateSystem {
        const acs = this.getAnchoringSystemById(model, anchoringSystemId);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return acs.basePlateSystems.find(x => x.id == basePlateSystemId)!;
    }

    private isBasePlateSystemSelected(basePlateSystemId: string, anchoringSystemId: string): boolean {
        return basePlateSystemId == this.design?.getSelectedBasePlateSystemId(anchoringSystemId);
    }

    private isAnchorChannelAvailable(model: IModelCW, id: string): boolean {
        return (model.anchoringSystems.find(x => x.id == id)?.anchorChannel?.channelLength ?? 0) > 0;
    }

    private initGLModel() {
        return new Promise<void>(resolve => {
            const displayOptions = this.userSettingsService.settings.application.curtainWall.modelDisplayOptions;

            const model: IModelCW = {
                applicationType: this.design?.applicationType ?? ApplicationTypes.None,
                baseMaterial: {} as IBaseMaterial,
                anchoringSystems: [{
                    id: '1',
                    geometry: {
                        position: Vector3.Zero(),
                        rotation: Vector3.Zero()
                    } as IAnchoringSystemGeometry,
                    anchorChannel: {
                        rebarChannel: {} as IRebarChannel
                    } as IAnchorChannel,
                    rebarPlate: {} as IRebarPlate,
                    basePlateSystems: [{
                        id: '',
                        bolt: {} as IBolt,
                        plateBracket: {
                            piBracket: {} as IPiBracket,
                            standoff: {} as IPlateStandoff,
                            slottedHole: {} as ISlottedHole,
                            loadEccentricity: Vector2.Zero()
                        } as IPlateBracket,
                    } as IBasePlateSystem],
                    isCorner: false
                }],
                loadCombinations: [] as ILoadCombinationValues[],
                isPostInstallAnchorProduct: this.design?.isPostInstallAnchorProduct() ?? false,
                visibilityProperties: {
                    baseMaterialTransparent: displayOptions?.transparentBaseMaterial.value ?? undefined,
                    bracketTransparent: displayOptions?.transparentBracket.value ?? undefined,
                    anchorChannelLenVisible: displayOptions?.visibleAnchorChannelLen.value ?? undefined,
                    concreteDimensionVisible: displayOptions?.visibleConcreteDimension.value ?? undefined,
                    boltSpacingVisible: displayOptions?.visibleBoltSpacing.value ?? undefined,
                    bracketDimensionsVisible: displayOptions?.visibleBracketDimensions.value ?? undefined,
                    bracketOffsetVisible: displayOptions?.visibleBracketOffset.value ?? undefined,
                    anchorNumberVisible: displayOptions?.visibleAnchorNumber.value ?? undefined,
                    boltNumberVisible: displayOptions?.visibleBoltNumber.value ?? undefined,
                    symmetricCornerVisible: displayOptions?.visibleSymmetricCorner.value ?? undefined,
                },

                anchoringSystem: (model: IModelCW, anchoringSystemId: string) => this.getAnchoringSystemById(model, anchoringSystemId),
                isAnchoringSystemSelected: (anchoringSystemId: string) => this.isAnchoringSystemSelected(anchoringSystemId),
                isAnchorChannelAvailable: (model: IModelCW, id: string) => this.isAnchorChannelAvailable(model, id),

                basePlateSystem: (model: IModelCW, basePlateSystemId: string, anchoringSystemId: string) => this.getBasePlateSystemById(model, basePlateSystemId, anchoringSystemId),
                isBasePlateSystemSelected: (basePlateSystemId: string, anchoringSystemId: string) => this.isBasePlateSystemSelected(basePlateSystemId, anchoringSystemId)
            };

            this.onBeforeCalculate = this.onBeforeCalculate.bind(this);
            this.onStateChanged = this.onStateChanged.bind(this);
            this.onCalculate = this.onCalculate.bind(this);

            (this.design as Design).onBeforeCalculate(this.onBeforeCalculate);
            (this.design as Design).onCalculate(this.onCalculate);
            (this.design as Design).onStateChanged(this.onStateChanged);

            this.glModel = {
                continuousRender: false,//this.activatedRoute.snapshot.queryParamMap.has('debug'),
                model,
                onFontsLoaded: () => {
                    this.updateGLModel(this.design as Design, null as unknown as Change[], Update.ServerAndClient, undefined, false, true);

                    setTimeout(() => {
                        this.glModelComponentRef.update({ hidden: false });

                        resolve();
                    }, 100);
                },
                onZoom: (zoom) => {
                    this.modelViewZoom = Math.round(100 - zoom);
                    this.zoomPercentage = this.unitService.formatInternalValueAsDefault(this.modelViewZoom < 0 ? 0 : this.modelViewZoom, UnitGroup.Percentage);
                    this.changeDetector.detectChanges();
                },
                onSelectTab: (tab) => {
                    this.selectTab(tab);
                },
                onPositionsChanged: () => {
                    // const updateObj: { [uiProperty: number]: boolean } = {};

                    //this.save2dState(updateObj);
                },
                onDraggingSelectionChanged: () => {
                    //this.setDraggingTooltipVisible(visible);
                }
            };
        });
    }

    private initMenu3d(selectedTab?: string) {
        if (this.design == null)
            return;

        this.mainMenuComponent?.initMenu3d(
            this.design,
            this.tabSelected,
            {
                [commandFromService(Command.OpenApproval)]: () => ApprovalHelper.openApprovalLinkOrModalDialog(this.design as Design, this.modalService, this.offlineService),
                [commandFromService(Command.OpenAnchorReinforcement)]: () => this.modalService.openAnchorReinforcement(),
                [commandFromService(Command.OpenFasteningTechnology)]: () => this.modalService.openFasteningTechnology(),
                [commandFromService(Command.OpenPlateStandoffPopup)]: () => this.modalService.openPlateStandoffPopup(),
                [commandFromService(Command.OpenInspectionTypePopup)]: () => this.modalService.openInspectionTypePopup(),
                [commandFromService(Command.OpenTorquingTypePopup)]: () => this.modalService.openTorquingTypePopup(),
                [commandFromService(Command.OpenInstallationFillHolesPopup)]: () => this.modalService.openInstallationFillHolesPopup(),
                [commandFromService(Command.OpenShowFullPortfolioPopup)]: () => this.modalService.openShowFullPortfolioPopup(() => this.design?.showFullPortfolioLinks)
            },
        );

        if (selectedTab != null) {
            this.selectTabById(selectedTab);
        }
    }

    private selectTab(tab: string) {
        (this.selectedMenu as IMenu).selectedTab = `tab-${tab}`;

        this.hideLeftMenu = false;

        this.resize3dAfterUI();
    }

    private tabSelected() {
        this.hideLeftMenu = false;

        this.resize3dAfterUI();
    }

    private initRightSide() {
        this.rightSideLoaded = true;
    }

    private resize3dAfterUI() {
        // the UI might update later so we resize it twice
        this.resize3d();

        setTimeout(() => {
            this.resize3d();
        });
    }

    private onKeyDown(event: KeyboardEvent) {
        const key = event.key?.toUpperCase() ?? '';

        // undo
        if (event.ctrlKey === true && key === 'Z') {
            event.stopPropagation();
            event.preventDefault();

            this.undo();
        }

        // redo
        if (event.ctrlKey === true && key === 'Y') {
            event.stopPropagation();
            event.preventDefault();

            this.redo();
        }

        // open import design popup
        if (event.ctrlKey === true && (key === 'I' || key === 'O')) {
            event.stopPropagation();
            event.preventDefault();

            this.designSectionComponent.nativeElement.openFile();
        }
    }

    public get displayOptionsTooltip() {
        return this.translate(this.view == ViewType.View3d ? 'Agito.Hilti.CW.Main.ShowHideElements' : 'Agito.Hilti.CW.Main.ShowHideElements2D');
    }

    public sortMenu3DRight(sortable: Sortable) {
        sortable.sort(this.favoritesService.menu3DRightOrder.map(String));
    }

    public get hasScopeChecks() {
        return this.scopeChecks.length > 0;
    }

    public get scopeChecks() {
        return this.design?.designData?.reportData?.scopeCheckResultItems ?? [];
    }

    public getScopeCheckHtml(scopeCheckMessage: TranslationFormat | undefined) {
        if (!scopeCheckMessage)
            return '';

        const transformedParams = this.translationFormatService.transformTranslationParameters(
            scopeCheckMessage.translationParameters,
            true,
            undefined,
            this.getNumericScopeCheckHtml.bind(this)
        );

        const html = this.translationFormatService.getLocalizedStringWithTranslationFormat(
            scopeCheckMessage,
            true,
            transformedParams
        ) ?? '';

        return html?.replace(/τ/g, '<span class="tauFontSmall">τ</span>');
    }

    private getNumericScopeCheckHtml(parameter: TranslationParameter, roundValue: boolean) {
        if (
            parameter.parameterType !== TranslationParameterTypes.Numerical ||
            !roundValue
        ) {
            // Handle only Numerical parameters with rounding
            return undefined;
        }

        const numericalParameter = parameter as NumericalParameter;
        if (numericalParameter.value == undefined) {
            return undefined;
        }

        return this.getNumericScopeCheckHtmlBase(numericalParameter.value, fromCwUnitGroup(numericalParameter.unitGroup), numericalParameter.additionalPrecision ?? 0);
    }

    private getNumericScopeCheckHtmlBase(numberValue: number, unitGroup: UnitGroup, additionalPrecision: number) {
        let unit = UnitType.None;
        if (unitGroup) {
            unit = this.unitService.getDefaultUnit(unitGroup);
        }

        const maxPrecision = this.unitService.getDefaultPrecision() + 1;  // Same as used in server code (UnitHelper.ConvertUnitTo)!
        const precision = this.unitService.getPrecision(unit) + additionalPrecision ?? 0;

        let displayedUnitValue = '';
        let exactUnitValue = '';

        if (unitGroup) {
            const internalUnit = this.unitService.getInternalUnit(unitGroup);

            numberValue = this.unitService.convertUnitValueArgsToUnit(numberValue, internalUnit, unit);
            displayedUnitValue = this.unitService.formatUnitValueArgs(numberValue, unit, precision, undefined, undefined, undefined, false);
            exactUnitValue = this.unitService.formatUnitValueArgs(numberValue, unit, maxPrecision, undefined, undefined, undefined, false);
        }
        else {
            displayedUnitValue = this.unitService.formatNumber(numberValue, precision);
            exactUnitValue = this.unitService.formatNumber(numberValue, maxPrecision);
        }

        if (displayedUnitValue.length >= exactUnitValue.length) {
            return undefined;
        }

        // Displayed with less decimals than internally used!
        //displayedUnitValue = this.unitService.appendPrecisionLossSymbolToValueString(numberValue, displayedUnitValue);
        displayedUnitValue = this.appendPrecisionLossSymbolToValueString(numberValue, displayedUnitValue);

        if (unitGroup) {
            displayedUnitValue = this.appendUnitToValueString(numberValue, displayedUnitValue, unit);
            exactUnitValue = this.appendUnitToValueString(numberValue, exactUnitValue, unit);
        }

        return `<span class="additional-info" title="${exactUnitValue}">${displayedUnitValue}</span>`;
    }

    public scopeCheckButtonClick(uiPropertyValues: UIPropertyValue[], infoPopup: DetailedScopeCheckInfoPopup = DetailedScopeCheckInfoPopup.None) {
        if (this.design == undefined)
            return;

        for (const uiProperty of uiPropertyValues) {
            this.design.addModelChangeNoCalculation(uiProperty.property, true, uiProperty.value);
        }

        if (infoPopup != DetailedScopeCheckInfoPopup.None) {
            this.modalService.openInfoScopeCheckDialog(infoPopup);
        }

        this.calculationService.calculateAsync(this.design);
    }

    public getButtonTitle(actionButton: ScopeCheckButtonParameter) {
        let translationText = this.localizationService.getString(actionButton.buttonTitle);

        if (actionButton.titleTranslationParameters == null) {
            return translationText;
        }

        for (const key in actionButton.titleTranslationParameters) {
            translationText = translationText.replace(`{${key}}`, actionButton.titleTranslationParameters[key]);
        }

        return translationText;
    }

    public notificationButtonsTooltip(translationKey: string) {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_ScopeCheckButtons) ||
            this.localizationService.getString(translationKey);
    }

    public getScopeCheckType(scopeCheck: ScopeCheckResultItemEntity) {
        return scopeCheck.indicatesCalculationError || (scopeCheck.specialFlags & ScopeCheckFlags.DisplayAsCalculationError)
            ? NotificationType.alert
            : NotificationType.info;
    }

    public get notificationScopeChecks() {
        return this.scopeChecks.map(sc => ({
            type: this.getScopeCheckType(sc),
            message: this.getScopeCheckHtml(sc.message),
            actionButtons: sc.actionButtons?.map(ab => ({
                condition: () => { return true; },
                disabled: () => { return false; },
                click: () => {
                    return this.ngZone.run(() => this.scopeCheckButtonClick(ab.uiPropertyValueList, sc.infoPopup ?? DetailedScopeCheckInfoPopup.None));
                },
                buttonTitle: this.getButtonTitle(ab),
                tooltip: this.notificationButtonsTooltip(ab.buttonTooltip),
                disableTooltip: () => { return !this.localizationService.hasTranslation(ab.buttonTooltip); }
            })),
            hasSupportButton: () => sc.showSupportButton,
            supportButtonClick: () => { this.scopeCheckSupportButtonClick(); }
        } as INotificationScopeCheck));
    }

    public scopeCheckSupportButtonClick() {
        if (this.design?.projectDesign == null)
            throw new Error('Project design is not set.');

        // Open support modal
        const appError = this.calculationService.getLatestAppErrorInfo();
        this.modalService.openSupport(appError, this.design.projectDesign);
    }

    public setNotificationComponentInputs() {
        this.notificationComponentInputs = {
            isVisible: () => {
                return this.design && this.hasScopeChecks;
            },
            notifications: [],
            isInfoMessageVisible: () => { return true; },
            scopeChecks: this.notificationScopeChecks
        } as INotificationsComponentInput;
    }

    private appendPrecisionLossSymbolToValueString(value: number, valueString: string) {
        if (Number.isFinite(value)) {
            return `${valueString}…`;
        }

        return valueString;
    }

    private appendUnitToValueString(value: number, valueString: string, unit?: UnitType) {
        if (unit == undefined || unit == UnitType.None) {
            return valueString;
        }

        if (unit == UnitType.percent) {
            return valueString + this.formatUnit(unit);
        }

        if (Number.isFinite(value)) {
            return `${valueString} ${this.formatUnit(unit)}`;
        }

        return valueString;
    }

    public formatUnit(unit: UnitType) {
        return this.unitService.getUnitStrings(unit)[0];
    }
}
