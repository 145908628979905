<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.CW.SelectProduct.Title')" closeButtonId="select-anchor-channel-close-button">
</pe-modal-header>

<pe-modal-body  id="product-tab-select-anchor-channel-dialog" *ngIf="dataLoaded">
    <div class="modal-body-content">
        <div class="left-side">
            <div class="filters-title-row input-filters">
                <div class="input-filter-row">
                    <div class="input-filter-content">
                        <div class="input-filter-content-column">
                            <p class="checkbox-filters-title" [innerHtml]="translate('Agito.Hilti.CW.SelectProduct.CheckboxFilters')"></p>
                        </div>
                        <div class="input-filter-content-column">
                            <pe-button
                                [id]="'input-filter-clear-all'"
                                class="modal-button input-filter-content-column-button"
                                [look]="'Default'"
                                (buttonClick)="onClearAllButtonClick()"
                                [text]="translate('Agito.Hilti.CW.SelectProduct.ClearAllFilters')">
                            </pe-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="input-filters" *ngIf="showPostInstallProductFilters">
                <div id="fixture-thickness" class="input-filter-row">
                    <p class="input-filter-title title-strike" [innerHtml]="translate('Agito.Hilti.CW.SelectProduct.FixtureThicknes')"></p>

                    <div class="input-filter-content">
                        <div class="input-filter-content-column">
                            <pe-numeric-text-box
                                [id]="'selectproduct-fixture-thickness-min-value'"
                                [title]="translate('Agito.Hilti.CW.SelectProduct.FixtureThicknes.Min')"
                                [value]="fixtureThicknessMin"
                                (valueChange)="filterInputValueChanged($any($event).detail, 'fixtureThicknessMin')"
                                [unit]="unitLength"
                                [minValue]="0"
                                [maxValue]="500"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="input-filter-content-column">
                            <pe-numeric-text-box
                                [id]="'selectproduct-fixture-thickness-max-value'"
                                [title]="translate('Agito.Hilti.CW.SelectProduct.FixtureThicknes.Max')"
                                [value]="fixtureThicknessMax"
                                (valueChange)="filterInputValueChanged($any($event).detail, 'fixtureThicknessMax')"
                                [unit]="unitLength"
                                [minValue]="0"
                                [maxValue]="500"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true">
                            </pe-numeric-text-box>
                        </div>
                    </div>
                </div>

                <div id="hole-diameter" class="input-filter-row">
                    <p class="input-filter-title title-strike" [innerHtml]="translate('Agito.Hilti.CW.SelectProduct.HoleDiameter')"></p>

                    <div class="input-filter-content">
                        <div class="input-filter-content-column">
                            <pe-numeric-text-box
                                [id]="'selectproduct-hole-diameter-min-value'"
                                [title]="translate('Agito.Hilti.CW.SelectProduct.HoleDiameter.Min')"
                                [value]="holeDiameterMin"
                                (valueChange)="filterInputValueChanged($any($event).detail, 'holeDiameterMin')"
                                [unit]="unitLength"
                                [minValue]="0"
                                [maxValue]="100"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="input-filter-content-column">
                            <pe-numeric-text-box
                                [id]="'selectproduct-hole-diameter-max-value'"
                                [title]="translate('Agito.Hilti.CW.SelectProduct.HoleDiameter.Max')"
                                [value]="holeDiameterMax"
                                (valueChange)="filterInputValueChanged($any($event).detail, 'holeDiameterMax')"
                                [unit]="unitLength"
                                [minValue]="0"
                                [maxValue]="100"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true">
                            </pe-numeric-text-box>
                        </div>
                    </div>
                </div>

                <div class="input-filter-row">
                    <p class="input-filter-title title-strike"
                        [ngbTooltip]=""
                        [disableTooltip]="true">
                        <span
                            class="input-filter-title-label" [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.Torquing')"></span>
                        <button type="button" class="control-tooltip-popup pe-ui-cw-sprite pe-ui-cw-sprite-info-tooltip"
                            *ngIf="true"
                            (click)="onTorquingInfoClicked()"
                            [ngbTooltip]="translate('Agito.Hilti.Profis3.ControlTooltip.Popup')"></button>
                    </p>

                    <div class="input-filter-custom-checkbox">
                        <pe-checkbox-button
                            *ngIf="true"
                            [items]="manualTorquingCheckbox.items"
                            [selectedValues]="manualTorquingCheckbox.selectedValues"
                            (selectedValuesChange)="onTorquingChange($any($event).detail, true)"
                            [disabled]="true">
                        </pe-checkbox-button>
                    </div>
                    <div class="input-filter-custom-checkbox">
                        <pe-checkbox-button
                            *ngIf="true"
                            [items]="adaptiveTorquingCheckbox.items"
                            [selectedValues]="adaptiveTorquingCheckbox.selectedValues"
                            (selectedValuesChange)="onTorquingChange($any($event).detail, true)"
                            [disabled]="false">
                        </pe-checkbox-button>
                    </div>
                </div>

                <div class="input-filter-row">
                    <p class="input-filter-title title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.BoreholeConditions')"></p>
                        <div class="input-filter-content">
                            <pe-dropdown
                                class="full-width"
                                [id]="boreholeConditionDropdown.id"
                                [title]="boreholeConditionDropdown.title"
                                [notSelectedText]="boreholeConditionDropdown.notSelectedText"
                                [items]="boreholeConditionDropdown.items"
                                [selectedValue]="boreholeConditionDropdown.selectedValue"
                                (selectedValueChange)="onBoreholeConditionChange($any($event).detail, true)">
                            </pe-dropdown>
                        </div>
                </div>

                <div class="input-filter-row">
                    <p class="input-filter-title title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.SelectAnchor.DrillingAndCleaning')"></p>
                    <div class="input-filter-content">
                        <pe-dropdown
                            class="full-width"
                            [id]="drillingAndCleaningDropdown.id"
                            [title]="drillingAndCleaningDropdown.title"
                            [notSelectedText]="drillingAndCleaningDropdown.notSelectedText"
                            [items]="drillingAndCleaningDropdown.items"
                            [selectedValue]="drillingAndCleaningDropdown.selectedValue"
                            (selectedValueChange)="onDrillingAndCleaningDropdownChange($any($event).detail, true)">
                        </pe-dropdown>
                    </div>
                </div>
            </div>

            <div class="checkbox-filters">
                <div class="checkbox-group" [id]="getControlId(translate(filterGroup.titleKey ?? ''))" *ngFor="let filterGroup of filterGroups;">
                    <ng-template #filterGroupTooltipTemplate>
                        <div class="control-tooltip">
                            <p class="title" [innerHtml]="filterGroup.infoPopup?.title" *ngIf="filterGroup.infoPopup?.title != null"></p>
                            <p class="content" [innerHtml]="filterGroup.infoPopup?.content" *ngIf="filterGroup.infoPopup?.content != null"></p>
                        </div>
                    </ng-template>

                    <p class="checkbox-group-title title-strike"
                        [ngbTooltip]="filterGroupTooltipTemplate"
                        [disableTooltip]="!filterGroup.infoPopup?.enabled">
                        <span
                            class="checkbox-group-title-label"

                            [innerHtml]="translate(filterGroup.titleKey ?? '')">
                        </span>
                        <button type="button" class="control-tooltip-popup pe-ui-pe-sprite pe-ui-pe-sprite-info-tooltip"
                            *ngIf="filterGroup.infoPopup?.show" (click)="openFilterGroupInfoPopup()"
                            [ngbTooltip]="translate('Agito.Hilti.CW.ControlTooltip.Popup')"></button>
                    </p>

                    <div class="checkbox-item checkbox" *ngFor="let filterItem of filterGroup.items;">
                        <pe-checkbox-button
                            [id]="getFilterItemCheckbox(filterItem.checkbox).id"
                            [items]="getFilterItemCheckbox(filterItem.checkbox).items"
                            [selectedValues]="getFilterItemCheckbox(filterItem.checkbox).selectedValues"
                            (selectedValuesChange)="getFilterItemCheckbox(filterItem.checkbox).selectedValues = $any($event).detail; onFilterCheckboxChange(filterItem, filterGroup)"
                            [disabled]="getFilterItemCheckbox(filterItem.checkbox).disabled">
                        </pe-checkbox-button>
                        <div class="muted-label"
                            *ngIf="showCompressedAirCleaningFilterNotification(filterItem.id)"
                            [innerHtml]="translate(compressedAirCleaningNotificationValue)">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="right-side">
            <div class="top-side">
                <div class="filter-input-container">
                    <pe-text-box
                        [id]="'select-anchor-sort-anchor-name-textbox'"
                        [placeholder]="filterPlaceholder"
                        [value]="filter"
                        (valueChange)="onFilterChange($any($event).detail)"
                        [debounceChanges]="true">
                    </pe-text-box>

                    <button id="select-anchor-sort-cancel-button" type="button" class="cancel-filter"
                        (click)="resetFilter()" *ngIf="filter">
                        <span class="image pe-ui-cw-sprite pe-ui-pe-sprite-x-circle"></span>
                    </button>
                </div>

                <div class="sort-dropdown">
                    <pe-dropdown
                        [id]="sortDropdown.id"
                        [items]="sortDropdown.items"
                        [selectedValue]="sortDropdown.selectedValue"
                        (selectedValueChange)="onSortChange($any($event).detail)">
                    </pe-dropdown>
                </div>

                <div class="tooltip-wrapper">
                    <pe-button
                        class="modal-button calculate-all-button"
                        [look]="'Cta'"
                        width="128px"
                        [disabled]="calculateAllDisabled || products == null || products.length < 1"
                        (buttonClick)="onCalculateAllButtonClick()">
                        <pe-loading-button
                            [loading]="calculateAllLoading"
                            [text]="translate(calculateAllButtonKey)">
                        </pe-loading-button>
                    </pe-button>
                </div>
            </div>

            <div class="products"
                data-control-id='select-product-dialog-anchor-channel-list-section'
                id="selectproductdialoganchorchannellistsection">

                <virtual-scroller #scrollProducts
                    [items]="products" [parentScroll]="scrollElement" [scrollAnimationTime]="0"
                    [ngStyle]="{ 'height': (products.length * rebarRowHeight) + 'px' }">
                    <div id="select-product-{{product.id}}-row"
                        *ngFor="let product of $any(scrollProducts).viewPortItems; trackBy:identifyProduct"
                        class="product modal-grid-button"
                        [class.selected]="selectedProductId == product.id"
                        (click)="selectProduct(product)">
                        <div class="image-container">
                            <div class="no-image-container">
                                <span class="no-image sprite sprite-responsive pe-ui-cw-sprite-no-photo-available"></span>
                            </div>
                            <div [ngClass]="this.isPostInstalledAnchor(product.id) ? 'post-installed-anchor' : ''">
                                <span class="image sprite-responsive pe-ui-cw-sprite" [ngClass]="'pe-ui-cw-' + product.image"></span>
                            </div>
                        </div>
                        <div class="text-container">
                            <div class="text-wrapper">
                                <p class="product-name">{{product.name}}</p>
                            </div>
                        </div>
                    </div>
                </virtual-scroller>
            </div>
        </div>
    </div>
</pe-modal-body>
