import { Injectable } from '@angular/core';

import { CodeList } from '../entities/code-list';
import { AnchorChannelEmbedmentDepth } from '../entities/code-lists/anchor-channel-embedment-depth';
import { AnchorChannelFamily } from '../entities/code-lists/anchor-channel-family';
import { AnchorChannelFamilyGroup } from '../entities/code-lists/anchor-channel-family-group';
import { AnchorChannelLength } from '../entities/code-lists/anchor-channel-length';
import { ApplicationTypes } from '../entities/code-lists/application-types';
import { BaseMaterials } from '../entities/code-lists/base-material-cl';
import { BoltFamily } from '../entities/code-lists/bolt-family';
import { BoltLength } from '../entities/code-lists/bolt-length';
import { BoltSize } from '../entities/code-lists/bolt-size';
import { ConcreteMixes } from '../entities/code-lists/concrete-mixes';
import { DesignMethodGroup } from '../entities/code-lists/design-method-group';
import { DesignStandard } from '../entities/code-lists/design-standard';
import { DesignType } from '../entities/code-lists/design-type';
import { EdgeReinforcements } from '../entities/code-lists/edge-reinforcements';
import { FasteningTechnologies } from '../entities/code-lists/fastening-technologies';
import { InspectionTypes } from '../entities/code-lists/inspection-types';
import { LoadType } from '../entities/code-lists/load-type';
import { PlateShape } from '../entities/code-lists/plate-shape';
import { RebarPlate } from '../entities/code-lists/rebar-plate';
import { SeismicStressType } from '../entities/code-lists/seismic-stress-type';
import { ShearConditions } from '../entities/code-lists/shear-conditions';
import { TensionConditions } from '../entities/code-lists/tension-conditions';
import { UIProperty } from '../entities/code-lists/UI-property';
import { ICodeLists } from '../entities/design';
import { DesignCodeList } from '../entities/enums/design-code-list';
import { ProjectCodeList } from '../entities/enums/project-code-list';
import {
    CodeListResponse, DesignCodeListEntity
} from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';
import { LipStrengthClips } from '../entities/code-lists/lip-strength-clips';
import { StandoffTypes } from '../entities/code-lists/standoff-types';
import { LoadTolerances } from '../entities/code-lists/load-tolerances';
import { GenericRebars } from '../entities/code-lists/generic-rebars';
import { NumberOfPlates } from '../entities/code-lists/number-of-plates';
import { KBNumber } from '../entities/code-lists/kb-number';
import { AnchorFamily } from '../entities/code-lists/anchor-family';
import { AnchorSize } from '../entities/code-lists/anchor-size';
import { AnchorFilter } from '../entities/code-lists/anchor-filter';
import { AnchorFilterGroup } from '../entities/code-lists/anchor-filter-groups';
import { AnchorType } from '../entities/code-lists/anchor-type';
import { AnchorEmbedmentDepth } from '../entities/code-lists/anchor-embedment-depth';
import { EmbedmentOptimizationType } from '../entities/code-lists/embedment-optimization-types';
import { LoadCombinationEquation } from '../entities/code-lists/load-combination-equation';
import { LoadCombinationFactor } from '../entities/code-lists/load-combination-factor';
import { DesignStandardMethodRegionCodeList } from '../entities/code-lists/design-standard-method-region';
import { ConcreteSafetyFactorGammaC } from '../entities/code-lists/concrete-safety-factor-gamma-c';
import { DrillingMethods } from '../entities/code-lists/drilling-methods';
import { HoleTypes } from '../entities/code-lists/hole-types';
import { HoleConditions } from '../entities/code-lists/hole-conditions';
import { TorquingTypes } from '../entities/code-lists/torquing-types';
import { LongitudinalShearCalculation } from '../entities/code-lists/longitudinal-shear-calculation';
import { ConcreteReinforcements } from '../entities/code-lists/concrete-reinforcements';
import { BondConditions } from '../entities/code-lists/bond-conditions';
import { InstallationDirections } from '../entities/code-lists/installation-directions';
import { SeismicCategoryTypes } from '../entities/code-lists/seismic-category-type';
import { SeismicDesignTypes } from '../entities/code-lists/seismic-design-type';
import { SeismicDisplacementTypes } from '../entities/code-lists/seismic-displacement-type';
import { StandOffDesignMethods } from '../entities/code-lists/standoff-design-methods';
import { AvailabilityRegionLinks } from '../entities/code-lists/availability-region-links';

@Injectable({
    providedIn: 'root'
})
export class CodeListService {
    public designType?: DesignType;
    private _projectCodeLists: { [codeList: number]: CodeList[] } = {};

    public get projectCodeLists() {
        return this._projectCodeLists;
    }

    public set projectCodeLists(value) {
        this._projectCodeLists = value;
    }

    public initialize(data: CodeListResponse) {
        this.designType = DesignType.fromService(data.designType);

        this.projectCodeLists = {
            [ProjectCodeList.UIProperties]: data.uiProperties.map((codeList) => UIProperty.fromService(codeList)),
            [ProjectCodeList.DesignStandards]: data.designStandards.map((codeList) => DesignStandard.fromService(codeList)),
            [ProjectCodeList.DesignMethodGroups]: data.designMethodGroups.map((codeList) => DesignMethodGroup.fromService(codeList)),
            [ProjectCodeList.DesignStandardMethodRegion]: data.designStandardMethodRegion.map((codeList) => DesignStandardMethodRegionCodeList.fromService(codeList)),
            [ProjectCodeList.ConcreteSafetyFactorGammaC]: data.concreteSafetyFactorGammaC.map((codeList) => ConcreteSafetyFactorGammaC.fromService(codeList))
        };
    }

    public getPropertyValue(id: number, regionId: number, designStandardId = 0) {
        const uiProperties = this.projectCodeLists[ProjectCodeList.UIProperties] as UIProperty[];
        const propValues = uiProperties.find((property) => property.id == id);

        if (propValues?.propertyValues == null) {
            throw new Error(`UI property with id '${id}' does not exists.`);
        }

        let propertyValues = propValues.propertyValues.filter(property => property.regionId == regionId);
        if (propertyValues.length == 0) {
            propertyValues = propValues.propertyValues.filter(property => property.regionId == 0);
        }

        let propValByDesignStandard = propertyValues.filter(property => property.designStandardId == designStandardId);
        if (propValByDesignStandard.length == 0) {
            propValByDesignStandard = propertyValues.filter(property => property.designStandardId == 0);
        }

        // This is the final solution if everything is filtered out
        if (propValByDesignStandard.length == 0) {
            propValByDesignStandard = propValues.propertyValues.filter(property => property.regionId == 0);
        }

        return propValByDesignStandard[0];
    }

    public createDesignCodeLists(codeListResponse: DesignCodeListEntity) {
        const designCodeLists: ICodeLists = {};

        if (codeListResponse != null) {
            designCodeLists[DesignCodeList.ApplicationTypes] = this.getCodeListItems(codeListResponse, 'applicationTypes', ApplicationTypes.fromService);
            designCodeLists[DesignCodeList.FasteningTechnologies] = this.getCodeListItems(codeListResponse, 'fasteningTechnologies', FasteningTechnologies.fromService);
            designCodeLists[DesignCodeList.InspectionTypes] = this.getCodeListItems(codeListResponse, 'inspectionTypes', InspectionTypes.fromService);
            designCodeLists[DesignCodeList.ConcreteMixes] = this.getCodeListItems(codeListResponse, 'concreteMixes', ConcreteMixes.fromService);
            designCodeLists[DesignCodeList.BaseMaterials] = this.getCodeListItems(codeListResponse, 'baseMaterial', BaseMaterials.fromService);
            designCodeLists[DesignCodeList.EdgeReinforcements] = this.getCodeListItems(codeListResponse, 'edgeReinforcements', EdgeReinforcements.fromService);
            designCodeLists[DesignCodeList.ShearConditions] = this.getCodeListItems(codeListResponse, 'shearConditions', ShearConditions.fromService);
            designCodeLists[DesignCodeList.TensionConditions] = this.getCodeListItems(codeListResponse, 'tensionConditions', TensionConditions.fromService);
            designCodeLists[DesignCodeList.AnchorFamilies] = this.getCodeListItems(codeListResponse, 'anchorFamilies', AnchorFamily.fromService);
            designCodeLists[DesignCodeList.AnchorTypes] = this.getCodeListItems(codeListResponse, 'anchorTypes', AnchorType.fromService);
            designCodeLists[DesignCodeList.AnchorSizes] = this.getCodeListItems(codeListResponse, 'anchorSizes', AnchorSize.fromService);
            designCodeLists[DesignCodeList.EmbedmentOptimizationTypes] = this.getCodeListItems(codeListResponse, 'embedmentOptimizationTypes', EmbedmentOptimizationType.fromService);
            designCodeLists[DesignCodeList.AnchorEmbedmentDepths] = this.getCodeListItems(codeListResponse, 'anchorEmbedmentDepths', AnchorEmbedmentDepth.fromService);
            designCodeLists[DesignCodeList.AnchorFilterGroup] = this.getCodeListItems(codeListResponse, 'anchorFilterGroups', AnchorFilterGroup.fromService);
            designCodeLists[DesignCodeList.AnchorFilter] = this.getCodeListItems(codeListResponse, 'anchorFilters', AnchorFilter.fromService);
            designCodeLists[DesignCodeList.AnchorChannelFamilyGroup] = this.getCodeListItems(codeListResponse, 'anchorChannelFamilyGroups', AnchorChannelFamilyGroup.fromService);
            designCodeLists[DesignCodeList.AnchorChannelFamily] = this.getCodeListItems(codeListResponse, 'anchorChannelFamily', AnchorChannelFamily.fromService);
            designCodeLists[DesignCodeList.AnchorChannelLength] = this.getCodeListItems(codeListResponse, 'anchorChannelLengths', AnchorChannelLength.fromService);
            designCodeLists[DesignCodeList.AnchorChannelEmbedmentDepth] = this.getCodeListItems(codeListResponse, 'anchorChannel', AnchorChannelEmbedmentDepth.fromService);
            designCodeLists[DesignCodeList.RebarPlate] = this.getCodeListItems(codeListResponse, 'rebarPlates', RebarPlate.fromService);
            designCodeLists[DesignCodeList.LipStrengthClips] = this.getCodeListItems(codeListResponse, 'lipStrengthClips', LipStrengthClips.fromService);
            designCodeLists[DesignCodeList.PlateShapes] = this.getCodeListItems(codeListResponse, 'plateShapes', PlateShape.fromService);
            designCodeLists[DesignCodeList.LoadTypes] = this.getCodeListItems(codeListResponse, 'loadTypes', LoadType.fromService);
            designCodeLists[DesignCodeList.SeismicShearTypes] = this.getCodeListItems(codeListResponse, 'seismicShearTypes', cl => SeismicStressType.fromService(cl, 'SeismicShearTypeEntity'));
            designCodeLists[DesignCodeList.SeismicTensionTypes] = this.getCodeListItems(codeListResponse, 'seismicTensionTypes', cl => SeismicStressType.fromService(cl, 'SeismicTensionTypeEntity'));
            designCodeLists[DesignCodeList.BoltFamilies] = this.getCodeListItems(codeListResponse, 'boltFamilies', BoltFamily.fromService);
            designCodeLists[DesignCodeList.BoltSizes] = this.getCodeListItems(codeListResponse, 'boltSizes', BoltSize.fromService);
            designCodeLists[DesignCodeList.BoltLengths] = this.getCodeListItems(codeListResponse, 'boltLengths', BoltLength.fromService);
            designCodeLists[DesignCodeList.StandoffTypes] = this.getCodeListItems(codeListResponse, 'standoffTypes', StandoffTypes.fromService);
            designCodeLists[DesignCodeList.LoadTolerances] = this.getCodeListItems(codeListResponse, 'loadTolerances', LoadTolerances.fromService);
            designCodeLists[DesignCodeList.LoadTolerancesY] = this.getCodeListItems(codeListResponse, 'loadTolerancesY', LoadTolerances.fromService);
            designCodeLists[DesignCodeList.LoadTolerancesZ] = this.getCodeListItems(codeListResponse, 'loadTolerancesZ', LoadTolerances.fromService);
            designCodeLists[DesignCodeList.GenericRebars] = this.getCodeListItems(codeListResponse, 'genericRebars', GenericRebars.fromService);
            designCodeLists[DesignCodeList.NumberOfPlates] = this.getCodeListItems(codeListResponse, 'numberOfPlates', NumberOfPlates.fromService);
            designCodeLists[DesignCodeList.KBNumberControl] = this.getCodeListItems(codeListResponse, 'kbNumberControls', KBNumber.fromService);
            designCodeLists[DesignCodeList.KBNumberRegion] = this.getCodeListItems(codeListResponse, 'kbNumberRegions', KBNumber.fromService);
            designCodeLists[DesignCodeList.LoadCombinationEquation] = this.getCodeListItems(codeListResponse, 'loadCombinationEquations', LoadCombinationEquation.fromService);
            designCodeLists[DesignCodeList.LoadCombinationFactor] = this.getCodeListItems(codeListResponse, 'loadCombinationFactors', LoadCombinationFactor.fromService);
            designCodeLists[DesignCodeList.DrillingMethods] = this.getCodeListItems(codeListResponse, 'drillingMethods', DrillingMethods.fromService);
            designCodeLists[DesignCodeList.InstallationDirections] = this.getCodeListItems(codeListResponse, 'installationDirections', InstallationDirections.fromService);
            designCodeLists[DesignCodeList.HoleConditions] = this.getCodeListItems(codeListResponse, 'holeConditions', HoleConditions.fromService);
            designCodeLists[DesignCodeList.HoleTypes] = this.getCodeListItems(codeListResponse, 'holeTypes', HoleTypes.fromService);
            designCodeLists[DesignCodeList.TorquingTypes] = this.getCodeListItems(codeListResponse, 'torquingTypes', TorquingTypes.fromService);
            designCodeLists[DesignCodeList.LongitudinalShearCalculation] = this.getCodeListItems(codeListResponse, 'longitudinalShearCalculations', LongitudinalShearCalculation.fromService);
            designCodeLists[DesignCodeList.ConcreteReinforcements] = this.getCodeListItems(codeListResponse, 'concreteReinforcements', ConcreteReinforcements.fromService);
            designCodeLists[DesignCodeList.BondConditions] = this.getCodeListItems(codeListResponse, 'bondConditions', BondConditions.fromService);
            designCodeLists[DesignCodeList.StandOffDesignMethods] = this.getCodeListItems(codeListResponse, 'standOffDesignMethods', StandOffDesignMethods.fromService);
            designCodeLists[DesignCodeList.SeismicCategoryTypes] = this.getCodeListItems(codeListResponse, 'seismicCategories', SeismicCategoryTypes.fromService);
            designCodeLists[DesignCodeList.SeismicDesignTypes] = this.getCodeListItems(codeListResponse, 'seismicDesignTypes', SeismicDesignTypes.fromService);
            designCodeLists[DesignCodeList.SeismicDisplacementTypes] = this.getCodeListItems(codeListResponse, 'seismicDisplacementTypes', SeismicDisplacementTypes.fromService);
            designCodeLists[DesignCodeList.AvailabilityRegionLinks] = this.getCodeListItems(codeListResponse, 'availabilityRegionLinks', AvailabilityRegionLinks.fromService);
        }
        return designCodeLists;
    }

    private getCodeListItems(codeListResponse: DesignCodeListEntity, codeListPropName: keyof DesignCodeListEntity, codeListMapFn: (cl: any) => CodeList): CodeList[] {
        return codeListResponse[codeListPropName]?.map((codelist: any) => codeListMapFn(codelist)) ?? [];
    }

    public getDesignStandards() {
        return this.projectCodeLists != undefined ?
            this.projectCodeLists[ProjectCodeList.DesignStandards] as DesignStandard[] :
            [] as DesignStandard[];
    }

    public getDesignMethodGroups() {
        return this.projectCodeLists != undefined ?
            this.projectCodeLists[ProjectCodeList.DesignMethodGroups] as DesignMethodGroup[] :
            [] as DesignMethodGroup[];
    }

    public getDesignStandardMethodRegion() {
        return this.projectCodeLists != undefined ?
            this.projectCodeLists[ProjectCodeList.DesignStandardMethodRegion] as DesignStandardMethodRegionCodeList[] :
            [] as DesignStandardMethodRegionCodeList[];
    }

}
