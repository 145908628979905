import { hubConnection, signalR, SignalR } from '../../scripts/agito.signalr.cjs';
import { HttpRequest } from '@angular/common/http';
import { NgZone } from '@angular/core';
import * as signalRCore from '@microsoft/signalr';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { ConnectionType, InvokeOptions, IPendingRequest, ISignalRError, SignalRConnectionBase, SignalRConnectionOptions, SignalRServiceBase } from '@profis-engineering/pe-ui-common/services/signalr.common';
import { LoggerService } from './logger.service';
import { UserService } from './user.service';
import { GuidService } from './guid.service';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { Subject } from 'rxjs';
import { CalculationResultResponse, GenerateReportResponse } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Responses.js';
import { CodeListResponse } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.js';
import { SignalRExceptionHandler } from './helpers/signalr-exception-handler';

declare const Zone: any;

export function buildSignalRHubConnection(baseUrl: string, accessTokenFactory: () => string, onDisconnected: (coreConnection: signalRCore.HubConnection) => void) {
    const options = {
        accessTokenFactory,
        skipNegotiation: true,
        transport: signalRCore.HttpTransportType.WebSockets,
    } as signalRCore.IHttpConnectionOptions;

    const coreConnection = new signalRCore.HubConnectionBuilder()
        .withUrl(baseUrl, options)
        .configureLogging(signalRCore.LogLevel.Warning)
        .build();

    coreConnection.onclose(() => onDisconnected(coreConnection));
    return coreConnection;
}

export class SignalRConnection extends SignalRConnectionBase {
    private pendingRequests: Record<string, IPendingRequest>;

    private connection?: SignalR.Hub.Connection;
    private hubProxy?: SignalR.Hub.Proxy;

    private coreConnection?: signalR.HubConnection;

    // Unknown => not known yet if websockets (SignalR Core) or http long polling (SignalR legacy).
    private connectionType: ConnectionType;
    private idleTimeoutId?: number;

    private connectingPromise?: Promise<void>;

    private lastRequestId: string;
    private lastRequestData?: any;
    private lastResponseData?: any | ISignalRError;

    public calculationDone = new Subject<CalculationResultResponse>();
    public projectCodeListDone = new Subject<CodeListResponse>();
    public generateReportDone = new Subject<GenerateReportResponse>();

    constructor(
        private modalService: ModalService,
        private loggerService: LoggerService,
        private userService: UserService,
        private guidService: GuidService,
        private localizationService: LocalizationService,
        private apiService: ApiService,
        private authenticationService: AuthenticationService,
        private ngZone: NgZone,
        private options: SignalRConnectionOptions,
    ) {
        super();

        NgZone.assertInAngularZone();

        this.onProgress = this.onProgress.bind(this);
        this.onDone = this.onDone.bind(this);
        this.onFail = this.onFail.bind(this);
        this.onCalculationDone = this.onCalculationDone.bind(this);
        this.onProjectCodeListDone = this.onProjectCodeListDone.bind(this);
        this.onGenerateReportDone = this.onGenerateReportDone.bind(this);

        this.pendingRequests = {};

        this.connectionType = options.useHttpLongPolling ? ConnectionType.Http : ConnectionType.Unknown;

        this.setHubConnections();

        // when auth token changes
        this.userService.authenticated.subscribe(() => {
            this.ngZone.run(() => this.setHubConnections());
        });

        /*
        * Set initial lastRequestId, usable when:
        * - the calculation was not invoked
        * - the TransactionId is not accessible
        */
        this.lastRequestId = this.guidService.new();
    }

    public get connectionUrl() {
        if (this.connectionType != ConnectionType.Http && this.coreConnection != null) {
            return this.coreConnection.baseUrl;
        }
        else {
            return this.connection?.url ?? '';
        }
    }

    public get requestData() {
        return this.lastRequestData;
    }

    public get responseData() {
        return this.lastResponseData;
    }

    public get requestId() {
        return this.lastRequestId;
    }

    public setHubConnections() {
        NgZone.assertInAngularZone();

        if (this.options == null) {
            // no connection set
            return;
        }

        // no need for websockets (SignalR Core) if Http
        // if Unknown we don't yet know if it's websockets (SignalR Core) or http long polling (SignalR legacy) so we run both hub connection functions
        if (this.connectionType != ConnectionType.Http) {
            this.setHubConnectionInternal(this.options.signalRCoreServerUrl, this.options.signalRCoreServerHub);
        }

        this.setHttpHubConnectionInternal(this.options.legacySignalRServerUrl ?? '', this.options.legacySignalRCoreServerHub);
    }

    public request<TRequest, TResponse>(requestId: string, methodName: string, requestData: TRequest, options?: InvokeOptions, hideErrorDialogs?: boolean) {
        this.lastRequestData = requestData;
        return this.invoke<TResponse>(requestId, methodName, options, hideErrorDialogs, requestData);
    }

    private setHubConnectionInternal(url: string, hub: string) {
        NgZone.assertInAngularZone();

        // convert headers to query string
        const headers = this.userService.getHeaders(url, false);
        let headerQuery = '';

        if (this.options.accessToken == 'local') {
            for (const headerName in headers) {
                headerQuery += `&${encodeURIComponent(headerName)}=${encodeURIComponent(headers[headerName])}`;
            }
        }

        if (headerQuery.startsWith('&')) {
            headerQuery = `?${headerQuery.substring(1)}`;
        }

        const baseUrl = url + hub + headerQuery;
        const onDisconnected = this.onDisconnected.bind(this);

        if (headers != null && headers['HC-TransactionId'] != null) {
            this.lastRequestId = headers['HC-TransactionId'];
        }

        // if we are not connected to the correct SignalR instance (C2C for example or token changes) disconnect first but only if no requests are running
        // on connect we also check if we need to reconnect by calling setHubConnections
        if (this.coreConnection != null &&
            this.coreConnection.baseUrl != baseUrl &&
            Object.keys(this.pendingRequests).length == 0) {

            // stop will also call onDisconnected but it will be async and ignored
            // since we might already have a new connection with new pending requests that must not be canceled
            this.coreConnection.stop();

            this.onDisconnected(this.coreConnection);
            this.coreConnection = undefined;
        }

        // create connection if we don't have it
        if (this.coreConnection == null) {
            // SignalR Core
            if (this.options.accessToken == 'header' || (this.options.accessToken == 'local')) {
                this.coreConnection = buildSignalRHubConnection(baseUrl, () => this.userService.authentication.accessToken, onDisconnected);
            }
            else if ((this.options.accessToken == 'cookie')) {
                this.coreConnection = buildSignalRHubConnection(baseUrl, () => '', onDisconnected);
            }
            else {
                this.loggerService.log(
                    'SignalRConnection::setHubConnectionInternal this.options.accessToken is Unknown',
                    LogType.error);
            }
            this.coreConnection?.on('CalculationDone', (response: any) => {
                NgZone.assertNotInAngularZone();
                this.ngZone.run(() => this.onCalculationDone(response));
            });
            this.coreConnection?.on('ProjectCodeListDone', (response: any) => {
                NgZone.assertNotInAngularZone();
                this.ngZone.run(() => this.onProjectCodeListDone(response));
            });
            this.coreConnection?.on('GenerateReportDone', (response: any) => {
                NgZone.assertNotInAngularZone();
                this.ngZone.run(() => this.onGenerateReportDone(response));
            });
        }
    }

    private setHttpHubConnectionInternal(url: string, hub: string) {
        NgZone.assertInAngularZone();
        const zone = Zone.current;

        const headers = this.userService.getHeaders(url, false);

        if (headers != null && headers['HC-TransactionId'] != null) {
            this.lastRequestId = headers['HC-TransactionId'];
        }

        signalR.ajaxDefaults.headers = headers;

        // if we are not connected to the correct SignalR instance (C2C for example) disconnect first but only if no requests are running
        // no need to check the token since it can be changed on existing connection (line above)
        // on connect we also check if we need to reconnect by calling setHubConnections
        if (this.connection != null && this.connection.url != url) {
            // stop will also call onDisconnected but it will be async and ignored
            // since we might already have a new connection with new pending requests that must not be canceled
            this.onDisconnected(this.connection);

            this.connection.stop();
            this.connection = undefined;
        }

        // connect if we don't have a connection
        if (this.connection == null) {
            // legacy SignalR
            this.connection = hubConnection(url, {
                useDefaultPath: false,
            });
            this.hubProxy = this.connection.createHubProxy(hub);

            this.hubProxy.on('Progress', (requestId: string, progress: unknown) => {
                NgZone.assertNotInAngularZone();
                zone.run(() => this.onProgress(requestId, progress));
            });
            this.hubProxy.on('Done', (requestId: string, result: unknown) => {
                NgZone.assertNotInAngularZone();
                zone.run(() => this.onDone(requestId, result));
            });
            this.hubProxy.on('Fail', (requestId: string, response: string) => {
                NgZone.assertNotInAngularZone();
                zone.run(() => this.onFail(requestId, response));
            });
            this.hubProxy.on('CalculationDone', (response: any) => {
                NgZone.assertNotInAngularZone();
                this.ngZone.run(() => this.onCalculationDone(JSON.parse(response)));
            });
            this.hubProxy.on('ProjectCodeListDone', (response: any) => {
                NgZone.assertNotInAngularZone();
                this.ngZone.run(() => this.onProjectCodeListDone(JSON.parse(response)));
            });
            this.hubProxy.on('GenerateReportDone', (response: any) => {
                NgZone.assertNotInAngularZone();
                this.ngZone.run(() => this.onGenerateReportDone(JSON.parse(response)));
            });

            const connection = this.connection;
            this.connection.disconnected(() => {
                if (NgZone.isInAngularZone()) {
                    this.onDisconnected(connection);
                }
                else {
                    zone.run(() => this.onDisconnected(connection));
                }
            });
        }
    }

    private async cancelCalculation(requestId: string): Promise<void> {
        NgZone.assertInAngularZone();

        const request = this.pendingRequests[requestId];
        const methodName = 'CancelCalculation';

        if (request != null) {
            delete this.pendingRequests[requestId];
            request.deferred.reject();

            try {
                await this.connect();
                await this.invokeInternal<void>(methodName, this.guidService.new(), requestId);
            }
            catch (error) {
                // catchException will throw the error or block for redirect
                await this.catchException(error as string, requestId);
            }
        }
    }

    private invoke<T>(requestId: string, methodName: string, options?: InvokeOptions, hideErrorDialogs?: boolean, ...args: any[]): Promise<T> {
        NgZone.assertInAngularZone();

        options = options ?? {};

        const deferred = new Deferred<T>();
        let isCanceled = false;

        this.lastRequestId = requestId;

        this.connect()
            .then(() => this.invokeInternal<T>(methodName, requestId, ...args))
            .then(result => {
                this.lastResponseData = result;
                if (!isCanceled) {
                    this.onDone(requestId, result);
                }
            })
            .catch((reason: ISignalRError) => {
                this.lastResponseData = reason;
                if (!isCanceled) {
                    if (this.connectionType == ConnectionType.Websocket) {
                        const request = this.pendingRequests[requestId];

                        if (request != null) {
                            delete this.pendingRequests[requestId];
                            clearTimeout(request.timeoutId);
                        }
                    }

                    if (!hideErrorDialogs) {
                        this.catchException(reason, requestId)
                            .catch(error => deferred.reject(error));
                    }
                }
            });

        if (options.cancel != null) {
            options.cancel.finally(() => {
                isCanceled = true;

                if (pendingRequest != null) {
                    pendingRequest.isCanceled = true;
                }

                return this.cancelCalculation(requestId);
            });
        }

        // save pending request
        const pendingRequest: IPendingRequest = this.pendingRequests[requestId] = {
            deferred,
            onProgress: options.onProgress,
            timeoutId: this.createTimeout(methodName, requestId, deferred, options.timeout),
            cancel: options.cancel,
            isCanceled
        };

        return deferred.promise;
    }

    private async catchException(reason: ISignalRError | string, requestId?: string): Promise<void> {
        NgZone.assertInAngularZone();

        const exHandler = new SignalRExceptionHandler(this.modalService, this.localizationService, this.authenticationService, this.loggerService);
        await exHandler.handle(reason, this.connectionUrl, this.connectionType, requestId);
    }

    private invokeInternal<T>(methodName: string, requestId: string, ...args: any[]): Promise<T> {
        NgZone.assertInAngularZone();

        return new Promise<T>((resolve, reject) => {
            if (this.connectionType == ConnectionType.Websocket) {
                this.ngZone.runOutsideAngular(() => (this.coreConnection as signalR.HubConnection).stream(methodName, ...[requestId, ...args])
                    .subscribe({
                        next: value => {
                            NgZone.assertNotInAngularZone();
                            this.ngZone.run(() => {
                                // we have progress or result
                                if (value.progress != null || value.Progress != null) {
                                    this.onProgress(requestId, value.progress || value.Progress);
                                }
                                else {
                                    this.startIdleTimeout();
                                    resolve(value.result || value.Result);
                                }
                            });
                        },
                        complete: () => {
                            // already handled in the next part
                        },
                        error: error => {
                            NgZone.assertNotInAngularZone();
                            this.ngZone.run(() => {
                                // if we get an Error object just return the message
                                if (error != null && error instanceof Error) {
                                    error = error.message;
                                }

                                this.startIdleTimeout();
                                reject(error);
                            });
                        }
                    }));
            }
            else if (this.connectionType == ConnectionType.Http) {
                this.ngZone.runOutsideAngular(() => (this.hubProxy as SignalR.Hub.Proxy).invoke(methodName, ...[requestId, ...args])
                    .done((arg: any) => {
                        NgZone.assertNotInAngularZone();
                        this.ngZone.run(() => resolve(arg));
                    })
                    .fail((...args: any[]) => {
                        NgZone.assertNotInAngularZone();
                        this.ngZone.run(() => reject(...args));
                    }));
            }
            else {
                throw new Error('connection type not set');
            }
        });
    }

    private startIdleTimeout() {
        NgZone.assertInAngularZone();

        if (this.idleTimeoutId != null) {
            clearTimeout(this.idleTimeoutId);
        }

        this.idleTimeoutId = setTimeout(() => {
            // there might be new pending requests when timeout is triggered
            // ignore it since the pending request will set the timeout
            if (Object.keys(this.pendingRequests).length == 0) {
                // disconnect
                if (this.coreConnection != null) {
                    this.coreConnection.stop();
                    this.onDisconnected(this.coreConnection);
                    this.coreConnection = undefined;
                }

                // set up connection for the next connect that might come later
                this.setHubConnections();
            }
        }, SignalRConnection.idleTimeout);
    }

    private createTimeout<T>(methodName: string, requestId: string, deferred: Deferred<T>, timeout: number | undefined = undefined): number {
        NgZone.assertInAngularZone();

        if (timeout == 0) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return setTimeout(() => { }, 0);
        }

        return setTimeout(() => {
            const request = this.pendingRequests[requestId];

            if (request != null) {
                delete this.pendingRequests[requestId];

                const message = `Operation ${methodName} has timed out.`;

                // Only show timeout error if request wasn't canceled.
                if (request.cancel == null || !request.isCanceled) {
                    this.modalService.openAlertSignalRError({
                        response: message,
                        correlationId: requestId,
                        endPointUrl: this.coreConnection?.baseUrl ?? ''
                    });
                }

                deferred.reject(message);
            }
        }, timeout ?? this.options.signalRTimeoutInMilliseconds);
    }

    private connect(): Promise<void> {
        NgZone.assertInAngularZone();

        this.setHubConnections();

        if (this.connection?.state == SignalR.ConnectionState.Connected) {
            return Promise.resolve();
        }

        if (this.connectingPromise != null) {
            return this.connectingPromise;
        }

        return this.connectingPromise = (async () => {
            try {
                // ASP.NET Core SignalR
                if (this.connectionType != ConnectionType.Http) {
                    await this.signalRConnect();
                    this.connectionType = ConnectionType.Websocket;
                }
                else {
                    // go to catch and try legacy
                    throw new Error('websockets not supported');
                }
            }
            catch {
                await this.signalRConnectLegacy();

                this.connectionType = ConnectionType.Http;
            }
            finally {
                this.connectingPromise = undefined;
            }
        })();
    }

    private async signalRConnect(): Promise<void> {
        NgZone.assertInAngularZone();

        if (this.coreConnection == null)
            return;

        // return if we are already connected
        if (this.coreConnection.state == signalRCore.HubConnectionState.Connected) {
            return;
        }

        if (this.options.accessToken == 'header' || this.options.accessToken == 'local') {
            await this.connectionStart();
        }
        else if (this.options.accessToken == 'cookie') {
            try {
                await this.apiService.request(new HttpRequest('GET', this.options.signalRCoreInitSessionUrl, { withCredentials: true }));
                await this.connectionStart();
            } catch (error) {
                throw new Error(`SignalRConnection::request Error: ${error}`);
            }
        }
        else {
            throw new Error('SignalRConnection::connect this.options.accessToken is Unknown');
        }
    }

    private async connectionStart(): Promise<void> {
        NgZone.assertInAngularZone();

        if (this.coreConnection == null)
            return;

        try {
            await this.ngZone.runOutsideAngular(async () => await (this.coreConnection as signalR.HubConnection).start());
            this.loggerService.log('ASP.NET Core SignalR connected. Protocol: WebSockets', LogType.info);
        }
        catch (error) {
            this.loggerService.log('ASP.NET Core SignalR connection failed.', LogType.error, error);

            throw error;
        }
    }

    private signalRConnectLegacy(): Promise<void> {
        NgZone.assertInAngularZone();
        const zone = Zone.current;

        if (this.connection == null) {
            this.setHttpHubConnectionInternal(this.options.legacySignalRServerUrl ?? '', this.options.legacySignalRCoreServerHub);
        }

        return new Promise<void>((resolve, reject) => {
            this.ngZone.runOutsideAngular(() => {
                if (this.connection == null)
                    return;

                this.connection
                    .start({
                        transport: 'longPolling',
                        withCredentials: false
                    })
                    .done(value => {
                        NgZone.assertNotInAngularZone();
                        zone.run(() => {
                            this.loggerService.log('ASP.NET SignalR Legacy connected. Protocol: ' + value.transport.name, LogType.warn);

                            resolve();
                        });
                    })
                    .fail(reason => {
                        NgZone.assertNotInAngularZone();
                        zone.run(() => {
                            this.loggerService.log('ASP.NET SignalR Legacy connection failed.', LogType.error, reason);

                            const response: ISignalRError = {
                                message: reason != null && reason.context != null && reason.context.statusText != null && reason.context.statusText != '' ? reason.context.statusText : 'Unknown error',
                                data: {
                                    StatusCode: reason != null && reason.context != null && reason.context.status != null && typeof reason.context.status == 'number' ? reason.context.status : 500
                                }
                            };
                            reject(response);
                        });
                    });
            });
        });
    }

    private onCalculationDone(result: any) {
        this.calculationDone.next(result);
    }

    private onProjectCodeListDone(result: any) {
        this.projectCodeListDone.next(result);
    }

    private onGenerateReportDone(result: any) {
        this.generateReportDone.next(result);
    }

    private onProgress(requestId: string, progress: unknown) {
        NgZone.assertInAngularZone();

        const request = this.pendingRequests[requestId];
        request?.onProgress?.(progress);
    }

    private onDone(requestId: string, result: unknown) {
        NgZone.assertInAngularZone();

        const request = this.pendingRequests[requestId];

        if (request != null) {
            delete this.pendingRequests[requestId];
            clearTimeout(request.timeoutId);

            this.lastRequestId = requestId;

            request.deferred.resolve(result);
        }
    }

    private onFail(requestId: string, response: string) {
        NgZone.assertInAngularZone();

        const request = this.pendingRequests[requestId];

        if (request != null) {
            delete this.pendingRequests[requestId];
            clearTimeout(request.timeoutId);

            const data = JSON.parse(response);

            // In case request was canceled, we don't show error.
            if (request.cancel != null && request.isCanceled) {
                request.deferred.reject(data);
            }
            else {
                this.catchException({ message: data.ExceptionMessage, data }, requestId)
                    .catch(error => request.deferred.reject(error));
            }
        }
    }

    private onDisconnected(connection: SignalR.Hub.Connection | signalR.HubConnection) {
        NgZone.assertInAngularZone();

        const message = 'Client was disconnected.';

        // reject all requests for a connection
        // we might be closing an old connection while a new one is already opened
        // so we check the connection object and ignore if it's an old connection
        if (this.connection === connection || this.coreConnection === connection) {
            for (const requestId in this.pendingRequests) {
                const request = this.pendingRequests[requestId];

                delete this.pendingRequests[requestId];
                clearTimeout(request.timeoutId);

                request.deferred.reject(message);
            }

            // for Core SignalR also clear the connection object since it's in a broken state
            // first request will always fail with "WebSocket is not in the OPEN state" (bug in ASP.NET Core SignalR?)
            if (this.coreConnection === connection) {
                // we are already disconnected so we can just set it to null
                // on next request the connection will be reopened
                this.coreConnection = undefined;
            }
        }

        this.connectionType = ConnectionType.Unknown;
    }
}

export interface ISignalRServiceConstructor {
    userService: UserService;
    modal: ModalService;
    logger: LoggerService;
    guid: GuidService;
    localization: LocalizationService;
    apiService: ApiService;
    authenticationService: AuthenticationService;
    ngZone: NgZone;
    options: SignalRConnectionOptions;
}

export class SignalRService extends SignalRServiceBase {
    public common!: SignalRConnection;

    constructor(
        ctor: ISignalRServiceConstructor
    ) {
        super();

        this.common = new SignalRConnection(
            ctor.modal,
            ctor.logger,
            ctor.userService,
            ctor.guid,
            ctor.localization,
            ctor.apiService,
            ctor.authenticationService,
            ctor.ngZone,
            ctor.options
        );
    }
}
