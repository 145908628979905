import { lineConstants as lineConstantsBase } from '@profis-engineering/gl-model/line-helper';

const lineConstants = {
    ...lineConstantsBase,
    anchorChannelOffsetZ: 3 * lineConstantsBase.lineDimensionOffsetXZ,
    loadPerBoltLengthX: 25,
    loadPerBoltLengthY: 100,
    loadPerBoltPositionYOffset: 10,
    loadPerBoltScale: 0.5
    // Expand line constants if needed
};

const baseMaterialConstants = {
    infinitySize: 3,
    infinityPointsNumber: 10,
    infinityOffset: 4,
    maxHeight: 250,
    maxBoundingBoxSize: 50,
    maxConcreteLength: 200,
    cylinderNumberingOffset: 25,
    minEmbedment: 75,
    protrudingOffset: 0.05,
    symmetricCornerOffset: 15,
    anchorProtrudingHeight: 10
};

const forceConstants = {
    sustainedLoadDashedLine: 60,
    loadForceOffsetXY: 70,
    loadMomentOffset: -57,
    sustainedLoadForceZOffset: 85,
    sustainedLoadMomentXYOffset: -70,
    characteristicLoadSustainedForceZOffsetVertical: 65,
    characteristicLoadForceZOffsetHorizontal: 17
};

export class GlModelConstants {
    public static lineConstants: Readonly<typeof lineConstants> = lineConstants;
    public static baseMaterialConstants: Readonly<typeof baseMaterialConstants> = baseMaterialConstants;
    public static forceConstants: Readonly<typeof forceConstants> = forceConstants;
}
