import type schema from './environment.local.json';
import { QueryStringHelper } from '@profis-engineering/pe-ui-common/helpers/query-string-helper';
import { Constants } from '../cw/entities/constants';
import {
    EnvironmentHelper
} from '@profis-engineering/pe-ui-common/helpers/environment-helper';

export type Environment = typeof schema & {
    demoFeaturesEnabled: boolean;
    baseUrl: string;
    debugGlModel: boolean;
    /** contains files hashes like translations */
    manifest?: Record<string, string>;
};

export const environment: Environment = getEnvironmentSettings();

const qsHelper = new QueryStringHelper(window.location.search);

// setBaseUrl
environment.baseUrl = document.getElementsByTagName('base')[0].href;

if (environment.demoQueryEnabled) {
    environment.demoFeaturesEnabled = qsHelper.getBoolean(Constants.DemoQuery);
}

if (!environment.production && environment.demoFeaturesEnabled) {
    environment.cwEnabled = qsHelper.getBoolean(Constants.DemoQuery);
}

// set debug
environment.debugGlModel = environment.enableDebugGlModelQuery && qsHelper.getBoolean(Constants.DebugQuery);
environment.debugMode = !environment.production && qsHelper.getBoolean(Constants.DebugMode);

function getEnvironmentSettings(): Environment {
    const env: Environment = (window as any).environmentCW;
    const helper = new EnvironmentHelper(window.location.host);
    const [serviceVersion] = helper.getServiceVersions();

    if (serviceVersion != '') {
        env.calculationServiceUrl = helper.formatServiceVersion(env.calculationServiceUrl, serviceVersion);
        env.signalRCoreServerUrl = helper.formatServiceVersion(env.signalRCoreServerUrl, serviceVersion);
        env.signalRLegacyServerUrl = helper.formatServiceVersion(env.signalRLegacyServerUrl, serviceVersion);
    }

    return env ?? {};
}
