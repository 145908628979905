import { CommonCache } from '@profis-engineering/gl-model/cache/common-cache';
import { BaseComponentCW, IBaseComponentConstructorCW } from '../../../gl-model/base-component';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Color } from '@profis-engineering/gl-model/cache/material-cache';
import { ArrowDirection } from '@profis-engineering/gl-model/cache/vertex-data-cache';
import { getForceArrowMesh, getMomentArrowMesh } from '@profis-engineering/gl-model/components/loads-helper.js';
import { IDashedLine } from '@profis-engineering/gl-model/vertex-data-helper.js';
import { MeshBuilder } from '@profis-engineering/gl-model/mesh-builder.js';
import { UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { Color3 } from '@babylonjs/core/Maths/math.color.js';
import { InstancedMesh } from '@babylonjs/core/Meshes/instancedMesh.js';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode.js';
import { CreateIUnitText2DCreateOptions } from '@profis-engineering/gl-model/components/base-component.js';
import { UnitGroup } from '@profis-engineering/gl-model/external/unit-converter.js';
import { ISetValueOnTextOptions } from '@profis-engineering/gl-model/text/unit-text-2d.js';
import { Align } from '@profis-engineering/gl-model/text/text-2d.js';
import { CoordinateSystem } from '@profis-engineering/gl-model/components/coordinate-system.js';
import { UnitText2D } from '../../../gl-model/text/unit-text-2d';
import { KeyValue } from '@angular/common';
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';


export interface IForceValueTextPosition {
    start: Vector3;
    end: Vector3;
    up: Vector3;
    options?: ISetValueOnTextOptions;
}

export interface UIPropertyState {
    disabled: boolean;
    hidden: boolean;
}

export interface IArrowObject {
    mesh: InstancedMesh;
    text: UnitText2D;
    uiProperty: number;
    getUiProperty: () => UIPropertyState;
    alternating?: boolean;
    dashed?: boolean;
    color?: Color;
    value: number;

    getTransformNode: () => TransformNode;
}

export type ILoadSystemConstructor = IBaseComponentConstructorCW;

export interface ILoadSystem {
    load: IArrowObject;
    dashedLine: Mesh;
}

export interface ILoad {
    uiProperty: UIProperty;
    value: number;
    length?: number;
    direction: Vector3;
    rotation: Vector3;
    isMoment: boolean;
    mirrorArrowDirection: boolean;
    scale?: number;
    offsetVertically: number;
    offsetHorizontally?: Vector3;
    isCharacteristicDynamicLoad?: boolean;
}

export interface ILoadCombination {
    anchorSystemId: string;
    loadCombinationId: string;
    position: Vector3;
    loads: ILoad[];
    sustainedLoads: ILoad[];
    characteristicLoads: ILoad[];
}

export class LoadSystem extends BaseComponentCW {
    public static readonly circleTextOffset = 5;

    public loadCombination!: ILoadCombination;
    public sustainedLoadCombination!: ILoadCombination;
    public mesh?: Mesh;

    protected transformNode!: TransformNode;

    private values: ILoadSystem[] = [];

    private forceTextCtor: CreateIUnitText2DCreateOptions = { unitGroup: UnitGroup.Force };
    private momentTextCtor: CreateIUnitText2DCreateOptions = { unitGroup: UnitGroup.Moment };

    constructor(ctor: ILoadSystemConstructor) {
        super(ctor);
    }

    public update(): void { return; }

    public override getBoundingBoxes(): BoundingInfo[] {
        const boundingBoxes: BoundingInfo[] = [];

        if (this.mesh) {
            boundingBoxes.push(this.mesh.getBoundingInfo());
        }

        if (this.values) {
            this.values.forEach((value) => {
                boundingBoxes.push(value.load.mesh.getBoundingInfo());
                boundingBoxes.push(value.dashedLine.getBoundingInfo());
                if (value.load.text.mesh){
                    boundingBoxes.push(value.load.text.mesh.getBoundingInfo());
                }
            });
        }

        return boundingBoxes;
    }

    public updateValues(lc: ILoadCombination): void {
        this.loadCombination = lc;
        const anchoringSystem = this.model.anchoringSystem(this.model, lc.anchorSystemId);
        this.transformNode = this.getGlobalTransformNode(anchoringSystem.geometry.position, anchoringSystem.geometry.rotation);

        this.hide();

        const loadTypes = [
            { loads: lc.loads, isSustained: false, isCharacteristic: false, startIndex: 0 },
            { loads: lc.sustainedLoads, isSustained: true, isCharacteristic: false, startIndex: 10000 },
            { loads: lc.characteristicLoads, isSustained: false, isCharacteristic: true, startIndex: 100000 }
        ];

        for (const loadType of loadTypes) {
            let index = loadType.startIndex;

            for (const load of loadType.loads) {
                if (this.values[index] == undefined) {
                    this.values[index] = this.createLoadMesh(lc.loadCombinationId, load, loadType.isSustained, loadType.isCharacteristic);
                } else {
                    this.values[index]?.load?.mesh?.dispose();
                    this.values[index] = this.createLoadMesh(lc.loadCombinationId, load, loadType.isSustained, loadType.isCharacteristic, this.values[index].load, this.values[index].dashedLine);
                    this.updateDashedLine(this.values[index].dashedLine as LinesMesh, load);
                }

                this.setEnabled(this.values[index], !this.getUiProperty(this.values[index].load.uiProperty).hidden);
                index++;
            }
        }
    }

    public override hide(): void {
        this.values.forEach((value) => {
            this.setEnabled(value, false);
        });
    }

    private setEnabled(loadSystem: ILoadSystem, state: boolean): void {
        loadSystem?.load?.text?.setEnabled(state);
        loadSystem?.load?.mesh?.setEnabled(state);
        loadSystem?.dashedLine?.setEnabled(state);
    }

    private addValueToVector3(vector: Vector3, value: number): Vector3 {
        return vector.add(new Vector3(value * Math.sign(vector.x), value * Math.sign(vector.y), value * Math.sign(vector.z)));
    }

    private createLoadMeshCommon(loadCombinationId: string, load: ILoad, arrow?: IArrowObject, dashedLine?: Mesh, isSustained = false, isCharacteristic = false): ILoadSystem {
        const loadMesh = arrow ?? this.createArrowObject(load);
        loadMesh.value = load.value;

        loadMesh.mesh = this.getArrowMeshInstance(load, isSustained, isCharacteristic);

        let arrowLengthHalf = CommonCache.forceArrowLenghtHalf;
        if (load.scale) {
            loadMesh.mesh.scaling = new Vector3(load.scale, load.scale, load.scale);
            arrowLengthHalf *= load.scale;
        }

        if (!dashedLine) {
            dashedLine = this.getDashedLineMesh(load);
            dashedLine.position = this.loadCombination.position;
            dashedLine.rotation = load.direction.scale(Math.PI / 2);
        }

        const length = (load.length ?? CoordinateSystem.dashOver) + arrowLengthHalf;
        const arrDirection = new Vector3(-load.direction.z, -load.direction.y, load.direction.x);
        let position = this.loadCombination.position.add(this.addValueToVector3(arrDirection, length + load.offsetVertically));

        // Offset the arrow horizontally (in the Z direction) - used for characteristic loads
        if (load.offsetHorizontally)
            position = position.add(load.offsetHorizontally);

        loadMesh.mesh.position = position;
        loadMesh.mesh.rotation = load.rotation;

        if (load.isMoment) {
            this.getMomentTextMesh(loadMesh);
        }
        else {
            this.getForceTextMesh(loadMesh, load);
        }

        if (this.transformNode != null) {
            loadMesh.mesh.parent = this.transformNode;
            if (loadMesh.text.mesh) {
                loadMesh.text.mesh.parent = this.transformNode;
            }
            if (dashedLine) {
                dashedLine.parent = this.transformNode;
            }
        }

        loadMesh.text.onValueEntered = this.updateLoadValues(load.uiProperty, loadCombinationId);

        return {
            load: loadMesh,
            dashedLine: dashedLine
        };
    }

    public createLoadMesh(loadCombinationId: string, load: ILoad, isSustained?: boolean, isCharacteristic?: boolean, arrow?: IArrowObject, dashedLine?: Mesh): ILoadSystem {
        return this.createLoadMeshCommon(loadCombinationId, load, arrow, dashedLine, isSustained, isCharacteristic);
    }

    public override dispose(): void {
        this.values.forEach((value) => {
            this.hide();
            value.load.text?.dispose();
            value.load.text = undefined as unknown as UnitText2D;
            value.load.mesh?.dispose();
            value.load.mesh = undefined as unknown as InstancedMesh;

            value.dashedLine?.dispose();
            value.dashedLine = undefined as unknown as Mesh;
        });

        this.values = [];
    }

    protected getLongestComponent(vec: Vector3): 'x' | 'y' | 'z' {
        if (Math.abs(vec.z) > Math.abs(vec.x) && Math.abs(vec.z) > Math.abs(vec.y)) {
            return 'z';
        }

        if (Math.abs(vec.y) > Math.abs(vec.x) && Math.abs(vec.y) > Math.abs(vec.z)) {
            return 'y';
        }

        return 'x';
    }

    private getArrowDirection(value: number): ArrowDirection {
        return value >= 0 ? ArrowDirection.Out : ArrowDirection.In;
    }

    private getMirrorArrowDirection(value: number): ArrowDirection {
        return value >= 0 ? ArrowDirection.In : ArrowDirection.Out;
    }

    private getDirectionString(value: number): string {
        switch (value) {
            case ArrowDirection.In: {
                return 'In';
            }
            case ArrowDirection.Out: {
                return 'Out';
            }
            case ArrowDirection.Both: {
                return 'Both';
            }
        }

        return 'Unknown';
    }

    protected formatNumber(source: UnitText2D, internalValue?: number): string {
        return internalValue != null ? this.unitConverter.format(Math.abs(internalValue), source.unitGroup, source.property) : '';
    }

    private getForceTextPositions(arrowObject: IArrowObject, load: ILoad): IForceValueTextPosition {
        const c = this.getLongestComponent(arrowObject.mesh.position.subtract(this.loadCombination.position));
        const up = c == 'x' ? new Vector3(0, 0, 1) : new Vector3(-1, 0, 0);

        const scale = load.scale ?? 1;

        let direction = arrowObject.value < 0 ? 1 : -1;
        let offset = 0;
        if (c == 'z') {
            direction *= -1;
            offset = direction * (CommonCache.forceArrowLenght - CommonCache.forceArrowHeadLenght) * scale;
        }

        const start = arrowObject.mesh.position.clone();
        start[c] += direction * CommonCache.forceArrowLenghtHalf * scale - offset;

        const end = start.clone();

        end[c] -= direction * CommonCache.forceArrowLineLenght * scale - offset;

        return { start, end, up };
    }

    protected getForceTextMesh(forceMesh: IArrowObject, load: ILoad) {
        const { start, end, up } = this.getForceTextPositions(forceMesh, load);
        forceMesh.text.setValueOnLine(forceMesh.value, start, end, up, undefined, this.formatNumber.bind(this));
        forceMesh.text.setEnabled(!forceMesh.getUiProperty().hidden);
    }

    private getMomentTextPositions(arrowObject: IArrowObject): IForceValueTextPosition {
        const c = this.getLongestComponent(arrowObject.mesh.position.subtract(this.loadCombination.position));
        return this.getTextPositions(arrowObject.mesh, c);
    }

    protected getMomentTextMesh(momentMesh: IArrowObject) {
        const { start, end, up, options } = this.getMomentTextPositions(momentMesh);
        momentMesh.text.setValueOnLine(momentMesh.value, start, end, up, options, this.formatNumber.bind(this));
        momentMesh.text.setEnabled(!momentMesh.getUiProperty().hidden);
    }

    protected getTextPositions(momentMesh: AbstractMesh, coordinate: string): IForceValueTextPosition {
        const arrowDiameter = CommonCache.momentCircleArrowDiameterHalf;
        const offset = arrowDiameter - LoadSystem.circleTextOffset;

        let up: Vector3;
        let options: ISetValueOnTextOptions;

        const start = momentMesh.position.clone();
        const end = momentMesh.position.clone();

        switch (coordinate) {
            case 'x':
                start.z += offset - 1;
                end.z += offset + 1;
                up = new Vector3(0, 1, 0);

                options = {
                    alignX: Align.Start,
                    alignY: Align.Center,
                    skipRepos: true,
                    rotationY: Math.PI
                };
                break;
            case 'y':
                start.x -= offset + 1;
                end.x -= offset - 1;
                up = new Vector3(0, 1, 0);

                options = {
                    alignX: Align.End,
                    alignY: Align.Center,
                    skipRepos: true,
                    rotationY: Math.PI
                };
                break;
            case 'z':
                start.x += offset - 1;
                end.x += offset + 1;
                up = new Vector3(0, 0, 1);

                options = {
                    alignX: Align.Start,
                    alignY: Align.Center,
                    skipRepos: true,
                    rotationY: Math.PI
                };
                break;
            default:
                throw new Error('Unknown dimension.');
        }

        return { start, end, up, options };
    }

    private getArrowMeshInstanceCommon(load: ILoad, isSustained = false, isCharacteristic = false): InstancedMesh {
        let direction = 0;
        if (load.isCharacteristicDynamicLoad)
            direction = ArrowDirection.Both;
        else
            direction = load.mirrorArrowDirection ? this.getMirrorArrowDirection(load.value) : this.getArrowDirection(load.value);

        const directionString = this.getDirectionString(direction);

        const arrowType = load.isMoment ? 'MomentArrow' : 'ForceArrow';

        let arrowSuffix = '';
        if (isSustained)
            arrowSuffix = '_sustained';
        if (isCharacteristic)
            arrowSuffix = '_characteristic';

        const name = `${arrowType}_${directionString}${arrowSuffix}`;

        const getArrowMesh = load.isMoment ? getMomentArrowMesh : getForceArrowMesh;

        let color = Color.Black;
        if (isSustained || (isCharacteristic && !load.isCharacteristicDynamicLoad))
            color = Color.Blue;
        if (load.isCharacteristicDynamicLoad)
            color = '#FF0000' as Color; // TODO: this needs to be fixed as a part of task CW-3560

        const arrow = getArrowMesh(this.cache, this.scene, name, color, isSustained, direction);

        const fullName = `${name}_${load.uiProperty}_${directionString}_${this.id}_${this.loadCombination.loadCombinationId}${isSustained ? '_sustained' : ''}`;

        return arrow.createInstance(fullName);
    }

    private getArrowMeshInstance(loadType: ILoad, isSustained: boolean, isCharacteristic: boolean): InstancedMesh {
        return this.getArrowMeshInstanceCommon(loadType, isSustained, isCharacteristic);
    }

    private getUiProperty(uiPropertyId: number) {
        return {
            disabled: this.propertyInfo.isPropertyDisabled(uiPropertyId),
            hidden: this.propertyInfo.isPropertyHidden(uiPropertyId)
        };
    }

    protected createArrowObject(load: ILoad): IArrowObject {
        const textCtor = load.isMoment ? this.momentTextCtor : this.forceTextCtor;
        const uiPropertyId = load.uiProperty as number;
        const getTransformNode = () => this.transformNode;

        return {
            text: this.createUnitText2DForProperty(textCtor, uiPropertyId),
            getUiProperty: () => this.getUiProperty(uiPropertyId),
            getTransformNode,
            uiProperty: uiPropertyId,
            dashed: false,
            color: Color.Black,
            value: load.value,
            mesh: undefined as unknown as InstancedMesh,
        } as unknown as IArrowObject;
    }

    private updateLoadValues(property: UIProperty, loadCombinationId: string) {
        return (source: UnitText2D, internalValue: number | undefined) => {
            const propertyValue: KeyValue<string, number> = { key: loadCombinationId, value: internalValue ?? 0 };
            this.propertyInfo.setPropertyValue(property, propertyValue);
        };
    }

    private getDashedLineMesh(load: ILoad): Mesh {
        const instancedMeshName = `DashedLine_${load.uiProperty}_${this.id}_${this.loadCombination.loadCombinationId}`;

        const dashedLine = MeshBuilder.createDashedLines(instancedMeshName, this.scene, this.getLines(load));
        dashedLine.isPickable = false;
        dashedLine.color = Color3.FromHexString(Color.Black);
        dashedLine.alphaIndex = 3100;
        dashedLine.setEnabled(false);

        this.updateDashedLine(dashedLine, load);

        return dashedLine;
    }

    private updateDashedLine(dashedLine: LinesMesh, load: ILoad) {
        const lines: IDashedLine[] = this.getLines(load);
        MeshBuilder.updateDashedLines(dashedLine, lines);
        dashedLine.position = this.loadCombination.position;
        dashedLine.setEnabled(true);
    }

    private getLines(load: ILoad): IDashedLine[] {
        const dashSize = load.scale ? CoordinateSystem.dashSize * load.scale : CoordinateSystem.dashSize;
        const dashGapSize = load.scale ? CoordinateSystem.dashGapSize * load.scale : CoordinateSystem.dashGapSize;

        return [{
            dashSize,
            dashGapSize,
            direction: new Vector3(0, (load.length ?? CoordinateSystem.dashOver), 0),
            position: new Vector3()
        }];
    }
}
