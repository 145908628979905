import cloneDeep from 'lodash-es/cloneDeep';
import {
    AfterViewInit, Component, ElementRef, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit,
    SimpleChanges,
    TrackByFunction, ViewChild, ViewEncapsulation
} from '@angular/core';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { Design, DesignEvent, Properties } from '@profis-engineering/pe-ui-common/entities/design';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import {
    LoadCombinationEntity
} from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { PropertyMetaData } from '../../entities/properties';
import { LoadsComponentHelper } from '../../helpers/loads-component-helper';
import { CalculationService } from '../../services/calculation.service';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';
import { GuidService } from '../../services/guid.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';
import { Feature } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { environment } from '../../../environments/environmentCW';
import { LoadCombinationsCalculateTypes, LoadTypes, PlateShapes } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { ModalService } from '../../services/modal.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { RadioButtonProps, RadioLook } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { LicenseService } from '../../services/license.service';
import { DesignStandardHelper } from '../../helpers/design-standard-helper';

export const DEFAULT_MAX_LOADS_HEIGHT = 334;

export interface ILoadsWizardComponentInput {
    onSave?: () => void;
}

interface IDesignChangeDetectionModel {
    model: { [property: number]: any };
    properties: Properties;
}

@Component({
    templateUrl: './loads.component.html',
    styleUrls: ['../loads-row/loads-base.scss', './loads.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    private static readonly loadsRowHeight: number = 32; // sync with css variable @loads-row-height
    private static readonly resizedAndBorderPadding: number = 6; // 4 resizer + 2 borders

    @Input()
    public selectedLoadId!: string;

    @Input()
    public decisiveLoadId!: string;

    @Input()
    public disabled!: boolean;

    @Input()
    public parentElement?: HTMLElement;

    @Input()
    public resize3d!: (resetHighlightedComponents?: boolean) => void;

    public scrollLoadsFirstItemId?: string;

    @ViewChild('scrollLoadsCW')
    public scrollLoadsElement!: VirtualScrollerComponent;

    @ViewChild('utilizationColumnRef')
    public utilizationColumnRef!: ElementRef<HTMLElement>;

    public rowsLoaded!: boolean;

    public collapsed = false;
    public submitted!: boolean;

    public helper: LoadsComponentHelper;

    public propertyMetaData = PropertyMetaData;

    public loadsCalculationMode: LoadCombinationsCalculateTypes = LoadCombinationsCalculateTypes.Selected;
    public loadsCalculationModeRadio!: RadioButtonProps<LoadCombinationsCalculateTypes>;

    private tableHeight!: number;
    private destroyed!: boolean;

    // Change detection
    private oldDesign!: IDesignChangeDetectionModel;

    constructor(
        private localizationService: LocalizationService,
        private unitService: UnitService,
        private guidService: GuidService,
        private user: UserService,
        private calculationService: CalculationService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private featureVisibility: FeatureVisibilityService,
        private modalService: ModalService,
        private translationFormatService: TranslationFormatService,
        private ngZone: NgZone,
        codeListService: CodeListService,
        private elementRef: ElementRef<HTMLElement>,
        private licenseService: LicenseService
    ) {
        this.helper = new LoadsComponentHelper(
            localizationService,
            codeListService,
            unitService,
            modalService,
            translationFormatService,
            user.design
        );
    }

    public get design() {
        return this.user.design;
    }

    public get loads(): LoadCombinationEntity[] {
        return this.design.designData?.projectDesign?.loads.loadCombinations;
    }

    public get selectedLoad() {
        if (this.loads != null && this.selectedLoadId != null) {
            return this.loads.find((load) => load.id == this.selectedLoadId) ?? null;
        }

        return null;
    }

    public get selectedLoadTypeName() {
        return this.selectedLoad != null
            ? this.translate(`Agito.Hilti.Profis3.InfoSection.ActiveLoadSection.${this.helper.getLoadTypeAsString(this.selectedLoad.loadType)}`)
            : null;
    }

    public get utilizationFlexBasis() {
        return this.collapsed ? 0 : this.helper.resizerColumnWidth?.utilization ?? 0;
    }

    public get importLoadsTranslation() {
        return 'Agito.Hilti.Profis3.Loads.ImportLoadsCombinations';
    }

    public get forcesHeader() {
        return `${this.translate('Agito.Hilti.Profis3.Loads.Forces')} [${this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Force))}]`;
    }

    public get momentsHeader() {
        return `${this.translate('Agito.Hilti.Profis3.Loads.Moments')} [${this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Moment))}]`;
    }

    public get loadsHeader() {
        return this.translate('Agito.Hilti.Profis3.Loads.Loads');
    }

    public get titleTranslation() {
        return 'Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.ControlLoadCombinations.Title';
    }

    public get deleteLoadsDisabled() {
        return this.loads.length <= 1 || this.loads.every(l => l.isWizardGenerated);
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-trash',
            'sprite-anchor-shock',
            'sprite-anchor-seismic',
            'sprite-anchor-fatigue',
            'sprite-anchor-fire-resistant',
            'sprite-lines-expanded',
            'sprite-lines'
        );

        this.onStateChanged = this.onStateChanged.bind(this);
        this.design.onStateChanged(this.onStateChanged);

        this.helper.initialize();

        this.collapsed = this.loads == null;

        this.loadsCalculationModeRadio = {
            look: RadioLook.Horizontal,
            items: [
                {
                    id: 'calculation-mode-all',
                    value: LoadCombinationsCalculateTypes.All,
                    text: this.translate('Agito.Hilti.CW.Loads.CalculationMode.All')
                },
                {
                    id: 'calculation-mode-single',
                    value: LoadCombinationsCalculateTypes.Selected,
                    text: this.translate('Agito.Hilti.CW.Loads.CalculationMode.Selected')
                }
            ],
            selectedValue: this.loadsCalculationMode
        };

        this.loadsCalculationMode = this.design.loadsCalculationMode;

        this.oldDesign = this.cloneDesignForChangeDetection(this.design);

        this.sizeHeader = this.sizeHeader.bind(this);
        requestAnimationFrame(this.sizeHeader);

        this.setMinimumHeight();

        this.onCollapsedChanged();
    }

    ngAfterViewInit() {
        this.scrollLoadsFirstItemId = this.scrollLoadsElement?.items[0]?.Id;
        setTimeout(() => this.setCompact(), 400);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedLoadId'] != null) {
            this.selectedLoadChanged(this.selectedLoad);
        }
    }

    ngOnDestroy(): void {
        if (this.design != null)
            this.design.off(DesignEvent.stateChanged, this.onStateChanged);
        this.destroyed = true;
    }

    public setAllLoads(index: LoadTypes) {
        switch (index) {
            case LoadTypes.Static:
            case LoadTypes.Seismic:
            case LoadTypes.Dynamic:
            case LoadTypes.Fire:
                // register multiple updates of same property
                this.loads.filter(it => it.loadType != index).forEach(load => {
                    this.design.addModelChangeNoCalculation(PropertyMetaData.LoadCombination_CW_LoadType.id, true, { key: load.id, value: index });
                });
                // run calculation
                this.calculationService.calculateAsync(this.design).finally();
                break;
            default:
                throw new Error('Wrong load type id.');
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public formatForce(value: number) {
        if (value == null) {
            return null;
        }

        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Force);
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Force);
        const precision = this.unitService.getPrecision(defaultUnit);

        const defaultValue = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unitService.formatNumber(defaultValue, precision);
    }

    public formatMoment(value: number) {
        if (value == null) {
            return null;
        }

        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Moment);
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Moment);
        const precision = this.unitService.getPrecision(defaultUnit);

        const defaultValue = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unitService.formatNumber(defaultValue, precision);
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        // reset column widths on window resize
        this.helper.resizerColumnWidth = null;
        this.scrollLoadsElement?.refresh();
        this.setCompact();
    }

    public loadsCalculationModeRadioSelectedValueChange(selectedValue: LoadCombinationsCalculateTypes) {
        if (this.loadsCalculationMode != selectedValue) {
            this.loadsCalculationMode = selectedValue;

            this.submitted = true;

            this.calculationService.calculateAsync(this.design,
                (design) => {
                    design.loadsCalculationMode = this.loadsCalculationMode;
                }
            )
            .finally(() => {
                this.submitted = false;
            });
        }
    }

    public get loadsContainer() {
        return this.elementRef.nativeElement.shadowRoot?.querySelector('.loads-container') as HTMLElement;
    }

    public get resizerWrapper() {
        return this.elementRef.nativeElement.shadowRoot?.querySelector<HTMLElement>('.resizer-wrapper') as HTMLElement;
    }

    public onResize() {
        this.tableHeight = this.loadsContainer.offsetHeight;
        this.scrollLoadsElement.refresh();
        this.resize3d(true);
    }

    public onCollapsedChanged() {
        if (!this.collapsed) {
            // trigger rows load
            this.rowsLoaded = true;
        }

        this.adjustGridHeight();
        this.setMinimumHeight();
        if (this.resize3d != null) {
            this.resize3d();
        }
    }

    public selectLoad(loadId: string) {
        if (this.selectedLoadId != loadId) {
            this.selectedLoadId = loadId;

            this.selectedLoadChanged(this.selectedLoad);
        }
    }

    private adjustGridHeight() {
        if (this.collapsed) {
            this.removeGridHeight();
        }
        else {
            this.setGridHeight();
        }
    }

    public changeLoad(loadCombination: LoadCombinationEntity) {
        // Run calculation
        this.calculationService.calculateAsync(this.design,
            (design) => {
                this.updateDesignData(design, loadCombination);
            }).finally();
    }

    public updateDesignData(design: any, loadCombination: LoadCombinationEntity) {
        const old = this.design.designData.projectDesign.loads.loadCombinations.find(it => it.id == loadCombination.id);

        if (!old) {
            return;
        }

        const updateProperty = (UiPropertyId: number, newValue: any, oldValue: any) => {
            if (newValue !== oldValue) {
                design.model[UiPropertyId] = { key: loadCombination.id, value: newValue };
            }
        };

        updateProperty(PropertyMetaData.LoadCombination_CW_Name.id, loadCombination.name, old.name);
        updateProperty(PropertyMetaData.LoadCombination_CW_LoadType.id, loadCombination.loadType, old.loadType);
        updateProperty(PropertyMetaData.LoadCombination_CW_ForceX.id, loadCombination.forceX, old.forceX);
        updateProperty(PropertyMetaData.LoadCombination_CW_ForceY.id, loadCombination.forceY, old.forceY);
        updateProperty(PropertyMetaData.LoadCombination_CW_ForceZ.id, loadCombination.forceZ, old.forceZ);
        updateProperty(PropertyMetaData.LoadCombination_CW_MomentX.id, loadCombination.momentX, old.momentX);
        updateProperty(PropertyMetaData.LoadCombination_CW_MomentY.id, loadCombination.momentY, old.momentY);
        updateProperty(PropertyMetaData.LoadCombination_CW_MomentZ.id, loadCombination.momentZ, old.momentZ);
        updateProperty(PropertyMetaData.LoadCombination_CW_SustainedForceX.id, loadCombination.dynamicForceX, old.dynamicForceX);
        updateProperty(PropertyMetaData.LoadCombination_CW_SustainedForceY.id, loadCombination.dynamicForceY, old.dynamicForceY);
        updateProperty(PropertyMetaData.LoadCombination_CW_SustainedForceZ.id, loadCombination.dynamicForceZ, old.dynamicForceZ);
        updateProperty(PropertyMetaData.LoadCombination_CW_SustainedMomentX.id, loadCombination.dynamicMomentX, old.dynamicMomentX);
        updateProperty(PropertyMetaData.LoadCombination_CW_SustainedMomentY.id, loadCombination.dynamicMomentY, old.dynamicMomentY);
        updateProperty(PropertyMetaData.LoadCombination_CW_VariableForceX.id, loadCombination.variableForceX, old.variableForceX);
        updateProperty(PropertyMetaData.LoadCombination_CW_VariableForceY.id, loadCombination.variableForceY, old.variableForceY);
        updateProperty(PropertyMetaData.LoadCombination_CW_VariableForceZ.id, loadCombination.variableForceZ, old.variableForceZ);
    }

    public addNewLoad(newLoad: LoadCombinationEntity) {
        this.removeWizardGeneratedLoads();

        const loads = this.loads || [];

        const load = cloneDeep(newLoad);
        load.id = this.guidService.new();

        loads.push(load);
        this.design.designData.projectDesign.loads.loadCombinations = loads;
        this.design.designData.projectDesign.loads.selectedLoadCombinationId = load.id;

        this.helper.addingNewLoad = false;

        this.loadsContentScrollBottom();

        // Run calculation
        this.calculationService.calculateAsync(this.design);

        this.loadsContentScrollBottom();
    }

    public removeWizardGeneratedLoads() {
        this.design.designData.projectDesign.loads.loadCombinationWizard.loadCombinationWizardEquations = [];
        this.design.designData.projectDesign.loads.loadCombinationWizard.loadCombinationWizardFactors = [];

        this.design.designData.projectDesign.loads.loadCombinations = this.design.designData.projectDesign.loads.loadCombinations.filter(load => !load.isWizardGenerated);
    }

    public deleteLoads(loads: LoadCombinationEntity[]) {
        if (loads == null || this.loads.length <= 1) {
            return;
        }

        // one load combination is minimum
        const lastLoadCombinationId = this.selectedLoadId != null ? this.selectedLoadId : loads[0].id;

        // filter all loads that we want to delete
        const loadsIds = loads.filter((l) => !l.isWizardGenerated && l.id !== lastLoadCombinationId);

        this.design.deleteLoads = loadsIds.map((l) => l.id);

        // Run calculation
        this.calculationService.calculateAsync(this.design);
    }

    public columnsResized() {
        this.setCompact();
    }

    public virtualScrollChange(event: Event) {
        let eventItemId: string | undefined = undefined;
        if (event != null) {
            const event2 = event as any;
            if (Array.isArray(event2) && event2.length > 0) {
                eventItemId = event2[0]?.Id;
            }
        }

        if (event == null || eventItemId != this.scrollLoadsFirstItemId) {
            // If input element had focus we need to blur it on virtual scroll change
            // (viewport end/start index changed), but not if no actual scrolling happened
            const activeElement = document.activeElement as HTMLElement;
            if (activeElement != null && activeElement.tagName.toLowerCase() === 'input') {
                const loadRowsElement = document.getElementsByClassName('loads-rows')[0] as HTMLElement;
                if (loadRowsElement != null && loadRowsElement.contains(activeElement)) {
                    activeElement.blur();
                }
            }
        }

        this.scrollLoadsFirstItemId = eventItemId;
    }

    public identifyLoad: TrackByFunction<LoadCombinationEntity> = (index: number) => index;

    public get newLoadDisabled() {
        return this.submitted || this.helper.addingNewLoad || this.loads == null
            || this.helper.isPerBolt || this.helper.isMultipleBrackets || this.design.isReadOnlyDesignMode
            || this.multiLoadCombinationFeatureDisabled || this.helper.newAndImportLoadDisabled
            || (this.isFreeLicense && !DesignStandardHelper.isEnBasedDesignStandard(this.design?.designStandard?.id));
    }

    public get loadWizardHidden() {
        return this.newLoadHidden || DesignStandardHelper.isEnBasedDesignStandard(this.design?.designStandard?.id);
    }

    public get maxLoadsReached() {
        return this.loads.length >= this.helper.maxLoads;
    }

    public get newLoadHidden() {
        return !(this.featureVisibility.isFeatureEnabled('CW_MultiLoadCombination') || environment.multiLoadCombinationEnabled);
    }

    public get numberOfPlates(): number {
        return this.design.model[PropertyMetaData.Plate_CW_NumberOfPlates.id] as number;
    }

    public get isFreeLicense(): boolean {
        return this.licenseService.floatingLimitReached || this.licenseService.isFree() || this.licenseService.isOnlyBasic();
    }

    public get isLoadsPerBolt(): boolean {
        return this.design.designData.projectDesign.basePlate.plateShape === PlateShapes.NoPlate;
    }

    public get loadTooltipTranslation() {
        if (this.isLoadsPerBolt)
            return this.translate('Agito.Hilti.CW.Features.Design.ShowInputLoads.Tooltip.DisabledByLoadsPerBolt');

        if (this.numberOfPlates > 1)
            return this.translate('Agito.Hilti.CW.Features.Design.ShowInputLoads.Tooltip.DisabledBy2Groups');

        const defaultTooltip = DesignStandardHelper.isFos3BasedDesignMethod(this.design?.designMethodGroup?.id) ? '' : this.translate('Agito.Hilti.CW.Features.Design.ShowInputLoads.Tooltip');

        return defaultTooltip;
    }

    public get loadTooltip() {
        return this.newLoadDisabled || this.multiLoadCombinationFeatureDisabled
            ? this.loadTooltipTranslation
            : null;
    }

    public get openWizardTranslation() {
        return this.loads.some((load) => load.isWizardGenerated)
            ? this.translate('Agito.Hilti.CW.LoadsWizard.Popup.Edit')
            : this.translate('Agito.Hilti.CW.LoadsWizard.Popup.Create');
    }

    public get multiLoadCombinationFeatureDisabled() {
        return this.featuresVisibilityInfo.isDisabled(Feature.Design_LoadCombination, this.design?.region.id) ?? false;
    }

    public get isPostInstalledAnchorSelected() {
        return this.design.isPostInstalledAnchorSelected;
    }

    public openNewLoadWarning() {
        if (this.design.designData?.projectDesign?.loads.loadCombinations.some((load) => load.isWizardGenerated)) {
            this.modalService.openConfirmChange({
                id: 'designChangedPopup',
                title: this.translate('Agito.Hilti.CW.General.Warning'),
                message: this.translate('Agito.Hilti.CW.LoadsWizard.Popup.SwitchToCustomLoadComabinations.Message'),
                confirmButtonText: this.translate('Agito.Hilti.CW.General.Yes'),
                cancelButtonText: this.translate('Agito.Hilti.CW.General.No'),
                onConfirm: (modal) => {
                    this.openNewLoad();
                    modal.close();
                },
                onCancel: (modal) => { modal.close(); }
            });
        } else {
            this.openNewLoad();
        }
    }

    public openNewLoad() {
        this.helper.initializeNewLoad();

        this.helper.addingNewLoad = true;

        this.setGridHeight();
        this.loadsContentScrollBottom();
        this.setMinimumHeight();
        this.resize3d();
    }

    public openWizardWarning() {
        // check if and loadCombinations is wizard generated
        if (this.design.designData?.projectDesign?.loads.loadCombinations.some((load) => load.isWizardGenerated)) {
            this.openHNAWizard();
        } else {
            this.modalService.openConfirmChange({
                id: 'designChangedPopup',
                title: this.translate('Agito.Hilti.CW.General.Warning'),
                message: this.translate('Agito.Hilti.CW.LoadsWizard.Popup.SwitchToWizardLoadComabinations.Message'),
                confirmButtonText: this.translate('Agito.Hilti.CW.General.Yes'),
                cancelButtonText: this.translate('Agito.Hilti.CW.General.No'),
                onConfirm: (modal) => {
                    this.openHNAWizard();
                    modal.close();
                },
                onCancel: (modal) => { modal.close(); }
            });
        }
    }

    public updateToDecisiveButton() {
        this.selectLoad(this.design.decisiveLoadCombination);
    }

    public updateDecisiveButtonVisible() {
        return this.design.isMultipleLoadCombinations &&
            this.loadsCalculationMode === LoadCombinationsCalculateTypes.All &&
            this.design.decisiveLoadCombination &&
            this.design.selectedLoadCombinationId !== this.design.decisiveLoadCombination;
    }

    private openHNAWizard() {
        const props: ILoadsWizardComponentInput = {
            onSave: () => {
                this.setGridHeight(0);
            }
        };
        this.modalService.openLoadsHNAWizard(props);
    }

    public importLoads() {
        if (this.loads.some(l => l.isWizardGenerated)) {
            this.modalService.openConfirmChange({
                id: 'switch-to-custom-load-combinations-warning',
                title: this.translate('Agito.Hilti.CW.General.Warning'),
                message: this.translate('Agito.Hilti.CW.LoadsWizard.Popup.SwitchToCustomLoadComabinations.Message'),
                confirmButtonText: this.translate('Agito.Hilti.CW.General.Yes'),
                cancelButtonText: this.translate('Agito.Hilti.CW.General.No'),
                onConfirm: (modal) => {
                    modal.close();
                    this.modalService.openImportLoads();
                },
                onCancel: (modal) => {
                    modal.close();
                }
            });
        }
        else {
            this.modalService.openImportLoads();
        }
    }

    private loadsContentScrollBottom() {
        this.scrollLoadsElement?.scrollToIndex(this.loads.length);
    }

    private selectedLoadChanged(load: LoadCombinationEntity | null) {
        if (this.selectedLoadId != this.design.selectedLoadCombinationId && load != null) {
            // Update model and run calculation
            this.calculationService.calculateAsync(this.design,
                (design) => {
                    design.selectedLoadCombinationId = load.id;
                }
            );
        }
    }

    private onStateChanged() {
        // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
        const onStateChanged = () => {
            let designChanged = false;
            const newDesign = this.design;

            const propertyIdsToCompare = [
                PropertyMetaData.LoadCombination_CW_Name.id,
                PropertyMetaData.LoadCombination_CW_LoadType.id,
                PropertyMetaData.LoadCombination_CW_ForceX.id,
                PropertyMetaData.LoadCombination_CW_ForceY.id,
                PropertyMetaData.LoadCombination_CW_ForceZ.id,
                PropertyMetaData.LoadCombination_CW_MomentX.id,
                PropertyMetaData.LoadCombination_CW_MomentY.id,
                PropertyMetaData.LoadCombination_CW_MomentZ.id,
                PropertyMetaData.LoadCombination_CW_SustainedForceX.id,
                PropertyMetaData.LoadCombination_CW_SustainedForceY.id,
                PropertyMetaData.LoadCombination_CW_SustainedForceZ.id,
                PropertyMetaData.LoadCombination_CW_SustainedMomentX.id,
                PropertyMetaData.LoadCombination_CW_SustainedMomentY.id,
                PropertyMetaData.LoadCombination_CW_VariableForceX.id,
                PropertyMetaData.LoadCombination_CW_VariableForceY.id,
                PropertyMetaData.LoadCombination_CW_VariableForceZ.id,
                PropertyMetaData.Load_CW_LoadType.id,
                PropertyMetaData.Loads_CW_SelectedLoadCombinationId.id,
                PropertyMetaData.Loads_CW_LoadCombinationsCalculateType.id,
                PropertyMetaData.Loads_CW_DeleteLoadCombinationsByIds.id
            ];

            for (const propertyId of propertyIdsToCompare) {
                if (this.oldDesign.model[propertyId] != newDesign.model[propertyId]) {
                    designChanged = true;
                    break;
                }
            }

            let propId = PropertyMetaData.Plate_CW_Shape.id;
            if (this.oldDesign.model[propId] != newDesign.model[propId]) {
                this.adjustGridHeight();
                this.helper.resetAllColumnsWidth();
                this.setCompact();
                designChanged = true;
            }

            propId = PropertyMetaData.Plate_CW_NumberOfPlates.id;
            if (this.oldDesign.model[propId] != newDesign.model[propId]) {
                this.adjustGridHeight();
                this.helper.resetAllColumnsWidth();
                this.setCompact();
                designChanged = true;
            }

            propId = PropertyMetaData.Bolt_CW_NumberOfBolts.id;
            if (this.oldDesign.model[propId] != newDesign.model[propId]) {
                if (this.helper.isPerBolt) {
                    this.adjustGridHeight();
                    designChanged = true;
                    this.helper.resetAllColumnsWidth();
                    this.setCompact();
                }
            }

            propId = PropertyMetaData.Loads_CW_Wizard.id;
            if (this.oldDesign.model[propId] != newDesign.model[propId]) {
                this.adjustGridHeight();
                designChanged = true;
                this.helper.resetAllColumnsWidth();
                this.setCompact();
            }

            propId = PropertyMetaData.Loads_CW_DeleteLoadCombinationsByIds.id;
            if (this.oldDesign.model[propId] != newDesign.model[propId]) {
                this.adjustGridHeight();
                designChanged = true;
                this.helper.resetAllColumnsWidth();
                this.setCompact();
            }

            propId = PropertyMetaData.AnchorChannel_CW_Family.id;
            if (this.oldDesign.model[propId] != newDesign.model[propId]) {
                this.helper.setLoadTypeTooltips();
            }

            if (designChanged) {
                this.oldDesign = this.cloneDesignForChangeDetection(this.design);
            }
        };
        return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
    }

    private sizeHeader() {
        if (!this.destroyed) {
            const header = this.elementRef.nativeElement.shadowRoot?.querySelector('.resizer-wrapper .loads-header .header-sizer') as HTMLElement;
            const subHeader = this.elementRef.nativeElement.shadowRoot?.querySelector('.resizer-wrapper .loads-sub-header .header-sizer') as HTMLElement;
            const rows = this.elementRef.nativeElement.shadowRoot?.querySelectorAll('.resizer-wrapper .loads-row-component') as NodeListOf<Element>;

            if (header != null) {
                if (rows.length > 0) {
                    const rowWidth = (rows[0] as HTMLElement).offsetWidth;

                    if (header.style.width != rowWidth + 'px') {
                        header.style.width = rowWidth + 'px';
                    }
                }
                else if (header.style.width != '') {
                    header.style.width = '';
                }
            }

            if (subHeader != null) {
                if (rows.length > 0) {
                    const rowWidth = (rows[0] as HTMLElement).offsetWidth;

                    if (subHeader.style.width != rowWidth + 'px') {
                        subHeader.style.width = rowWidth + 'px';
                    }
                }
                else if (subHeader.style.width != '') {
                    subHeader.style.width = '';
                }
            }

            requestAnimationFrame(this.sizeHeader);
        }
    }

    private setGridHeight(addSpace = 0) {
        let fullHeight = 0;

        if (this.tableHeight == null) { // if grid was not manually expanded, calculate new height
            let rowsHeight = this.loads.length;

            if (this.helper.addingNewLoad) { // Always account for additional row and space when adding new load
                rowsHeight += 1;
                addSpace += 17;
            }

            rowsHeight = rowsHeight * this.calculateRowHeight();

            rowsHeight++;   // First row has 2 borders, only 1 respected in calculateRowHeight()!

            const headersHeight = 4 + 32 + 36 + 48; // 4 resizer  32 header  36 subheader  48 footer

            fullHeight = Math.min(rowsHeight + headersHeight + addSpace, DEFAULT_MAX_LOADS_HEIGHT);
        }
        else {
            fullHeight = this.tableHeight;
        }
        this.loadsContainer.style.height = fullHeight + 'px';
    }

    private removeGridHeight() {
        this.loadsContainer.style.height = '';
    }

    public get loadRowNumber() {
        if (this.helper.showFatigue) {
            return 3;
        }

        return this.helper.hasSustainedLoads ? 2 : 1;
    }

    private calculateRowHeight(load?: LoadCombinationEntity) {
        const rowFactor = this.loadRowNumber;
        let height = rowFactor * (LoadsComponent.loadsRowHeight + 1); // +1 for bottom border that each row has

        // total height is actually loads.length * height + 1; so we add + 1 to the first load
        if (load != null && this.loads.length > 0 && this.loads[0] == load) {
            height++;
        }

        return height;
    }

    private setMinimumHeight() {
        const rowHeight = this.calculateRowHeight();
        const minimumHeight = !this.collapsed ? 122 + rowHeight : 0;
        this.loadsContainer.style.minHeight = minimumHeight + 'px';
    }

    private cloneDesignForChangeDetection(design: Design): IDesignChangeDetectionModel {
        return {
            model: cloneDeep(design.model),
            properties: cloneDeep(design.properties)
        };
    }

    private setCompact() {
        NgZone.assertInAngularZone();

        if (!this.helper.setCompactCollapsed(this.collapsed)) {
            setTimeout(() => {
                this.helper.calculateCompact(this.utilizationColumnRef?.nativeElement);
            }, 100);
        }
    }
}
