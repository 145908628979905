import { environment } from '../../environments/environmentCW';
import { Design } from '../entities/design';
import { ApprovalsEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';
import { DesignMethodGroups } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { PropertyMetaData } from '../entities/properties';
import { ModalService } from '../services/modal.service';
import { OfflineService } from '../services/offline.service';

export interface IApprovalInfo {
    name: string;
    url: string;
}

export class ApprovalHelper {
    public static readonly approvalUrlPart = 'cdn/';
    public static readonly approvalTranslationKeyEta = 'Agito.Hilti.CW.ViewApproval.ETA';
    public static readonly approvalTranslationKeyHna = 'Agito.Hilti.CW.ViewApproval.HNA';

    public static getApprovalKey(design: Design | undefined): string {
        switch (design?.designMethodGroup?.id) {
            case DesignMethodGroups.ACI31811:
            case DesignMethodGroups.ACI31814:
            case DesignMethodGroups.ACI31819:
            case DesignMethodGroups.CSAA23314:
            case DesignMethodGroups.CSAA23319:
                return ApprovalHelper.approvalTranslationKeyHna;

            case DesignMethodGroups.EN19924:
            case DesignMethodGroups.ETAG001:
            case DesignMethodGroups.AS5216:
            case DesignMethodGroups.EN19924FOS3:
            case DesignMethodGroups.ETAG001FOS3:
            case DesignMethodGroups.SOFA:
            case DesignMethodGroups.SOFAFOS3:
            case DesignMethodGroups.IS:
                return ApprovalHelper.approvalTranslationKeyEta;

            default:
                return '';
        }
    }

    public static openApprovalLinkOrModalDialog(design: Design, modalService: ModalService, offlineService: OfflineService) {
        const approvals = design.model[PropertyMetaData.AnchorChannel_CW_Approvals.id] as ApprovalsEntity[];

        // Open popup with multiple approvals showing allowing user to open each separately
        // and preventing browser to block second tab-opening as popup
        if (approvals.length > 1) {
            modalService.openMultipleApprovals(approvals);
        }
        // Open tab otherwise
        else if (approvals[0] != null && approvals[0].filename != '') {
            const approvalInfo = ApprovalHelper.getApprovalInfo(approvals[0]);
            offlineService.nativeLocalPathOpen(approvalInfo.url, approvalInfo.name, true, true);
        }
    }

    public static getApprovalInfo(approval: ApprovalsEntity): IApprovalInfo {
        return {
            name: approval.filename,
            url: `${environment.baseUrl}${ApprovalHelper.approvalUrlPart}${approval.fileUrl}`
        };
    }
}
