import cloneDeep from 'lodash-es/cloneDeep';

import {
    Design as DesignBase, DesignEvent, IBaseDesign, ICommonUIProperties, IDesignDeps as IDesignDepsBase,
    IDesignStateBase, IUnitProviderObject, StateChange
} from '@profis-engineering/pe-ui-common/entities/design';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    CalculationServiceBase, ICalculationResultBase, IPropertyChanges
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';
import { CommonCodeList, CommonCodeListServiceBase } from '@profis-engineering/pe-ui-common/services/common-code-list.common';

import { CalculationService } from '../services/calculation.service';
import { CodeListService } from '../services/code-list.service';
import { UserSettingsService } from '../services/user-settings.service';
import { CodeList } from './code-list';
import { BaseMaterials } from './code-lists/base-material-cl';
import { AnchorChannelFamily } from './code-lists/anchor-channel-family';
import { AnchorChannelFamilyGroup } from './code-lists/anchor-channel-family-group';
import { AnchorChannelLength } from './code-lists/anchor-channel-length';
import { DesignMethodGroup } from './code-lists/design-method-group';
import { DesignStandard } from './code-lists/design-standard';
import { DesignCodeList } from './enums/design-code-list';
import { ProjectCodeList } from './enums/project-code-list';
import {
    BasePlateEntity, DesignEntity, LoadCombinationEntity
} from './generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { PropertyMetaData } from './properties';
import { DesignExternalMetaData } from '@profis-engineering/pe-ui-common/services/document.common';
import { DesignType } from './enums/design-type';
import { BoltFamily } from './code-lists/bolt-family';
import { BoltLength } from './code-lists/bolt-length';
import { BoltSize } from './code-lists/bolt-size';
import {
    ApplicationTypes, ConcreteSafetyFactorGammaC, LoadCombinationsCalculateTypes, LoadTypes, PlateShapes, FasteningTechnologies,
    LongitudinalShearCalculationTypes,
    AnchorChannelTypes,
    DesignSubTypes,
    TorquingTypes
} from './generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { AnchorChannelEmbedmentDepth } from './code-lists/anchor-channel-embedment-depth';
import { DesignReportDataEntity as ReportData, ReportOptionsEntity } from './generated-modules/Hilti.CW.CalculationService.Shared.Entities.Calculation';
import { ApprovalsEntity } from './generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';
import { RebarPlate } from './code-lists/rebar-plate';
import { LipStrengthClips } from './code-lists/lip-strength-clips';
import { TrackingUsageCounter } from './tracking-usage-counter';
import { DesignHelper } from '../helpers/design-helper';
import { AnchoringSystem } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { GenericRebars } from './code-lists/generic-rebars';
import { LoadTolerances } from './code-lists/load-tolerances';
import { AnchorFamily } from './code-lists/anchor-family';
import { DialogsEntity, LoadCombinationWizardEntity } from './generated-modules/Hilti.CW.CalculationService.Shared.Entities.Dialogs';
import { AnchorType } from './code-lists/anchor-type';
import { AnchorSize } from './code-lists/anchor-size';
import { LoadsLegendType } from '@profis-engineering/pe-ui-common/components/loads-legend/loads-legend.common';
import { BondConditions } from './code-lists/bond-conditions';
import { DrillingMethods } from './code-lists/drilling-methods';
import { InstallationDirections } from './code-lists/installation-directions';
import { HoleConditions } from './code-lists/hole-conditions';
import { HoleTypes } from './code-lists/hole-types';
import { ProjectOpenType } from './tracking-entities';
import { AvailabilityRegionLinks } from './code-lists/availability-region-links';

export interface ICalculationResult extends ICalculationResultBase<Design> {
    reportData: unknown;
    dialogs?: DialogsEntity;
}

export interface IDesignDeps extends IDesignDepsBase {
    codeListService: CodeListService;
    calculationService: CalculationService;
    userSettings: UserSettingsService;
}

export interface ICodeLists {
    [codeList: number]: CodeList[];
}

export class DesignData {
    // Input
    public projectDesign!: DesignEntity;
    public designCodeLists!: ICodeLists;

    // Results
    public reportData?: ReportData;
}

export interface IDesignState extends IDesignStateBase {
    projectDesign?: DesignEntity;
    reportData?: ReportData;
    designCodelists?: ICodeLists;
}

export class DesignScreenshots {
    public reportDialog?: string;
    public report3dGeometry?: string;
    public report3dFixtures: (string | undefined)[] = [];
    public reportLoadsLegend?: string;
}

const MAX_STATES = 51;


export class Design extends DesignBase<DesignData> implements IBaseDesign, IUnitProviderObject {

    public projectOpenType: ProjectOpenType = ProjectOpenType.Unknown;

    public currentState?: IDesignState;
    public calculateDefer: Deferred<ICalculationResult>;
    public screenshots: DesignScreenshots;
    public usageCounter: TrackingUsageCounter;

    private codeListCommon: CommonCodeListServiceBase;
    private codeList: CodeListService;
    private calculationService: CalculationService;
    private userSettings: UserSettingsService;

    private pendingCloseDesign = false;

    private static commonProperties: ICommonUIProperties = {
        regionPropertyId: PropertyMetaData.Option_RegionId.id,
        designTypePropertyId: PropertyMetaData.ProjectDesignType.id,
        designStandardPropertyId: PropertyMetaData.Option_CW_DesignStandard.id
    };

    constructor(designDeps: IDesignDeps) {
        super(designDeps, Design.commonProperties);

        this.codeListCommon = designDeps.commonCodeList;
        this.codeList = designDeps.codeListService;
        this.calculationService = designDeps.calculationService;
        this.userSettings = designDeps.userSettings;
        this.screenshots = new DesignScreenshots();
        this.usageCounter = new TrackingUsageCounter(this.logger);

        this.stateChanged = this.stateChanged.bind(this);

        this.onStateChanged(this.stateChanged);

        this.calculateDefer = new Deferred<ICalculationResult>();

        this.designData = {
            projectDesign: {} as DesignEntity,
            designCodeLists: {}
        };
    }

    public get designType() {
        return this.codeList.designType;
    }

    override get designTypeId(): number {
        return DesignType.CurtainWall;
    }

    public get projectDesign() {
        return this.designData.projectDesign;
    }

    public override getDocumentDesign() {
        return DesignHelper.simplifyDesign(this.projectDesign);
    }

    public get reportData() {
        return this.designData.reportData;
    }

    public get designName() {
        return this.model[PropertyMetaData.Option_DesignName.id] as string;
    }
    public set designName(value) {
        this.model[PropertyMetaData.Option_DesignName.id] = value;
    }

    public get projectName() {
        return this.model[PropertyMetaData.ProjectName.id] as string;
    }
    public set projectName(value) {
        this.model[PropertyMetaData.ProjectName.id] = value;
    }

    public get productName() {
        return this.isPostInstallAnchorProduct() ?
            `${this.anchorType?.displayKey} ${this.anchorSize?.displayKey}` :
            this.anchorChannelProductName;
    }

    private get anchorChannelProductName() {
        const familyGroupName = this.anchorChannelFamilyGroup?.displayKey;
        const familyName = this.anchorChannelFamily?.displayKey;
        const isEmptyName = familyGroupName == undefined || familyName == undefined;
        return isEmptyName ? `` : `${familyGroupName} (${familyName})`;
    }

    public get trackingDisplayNames() {
        // Important note: Display names for tracking should be in invariant culture (en-US), use displayKey instead of translated code list values

        return {
            'BaseMaterial': this.getBaseMaterialDisplayName,
            'ReinforcementTension': this.anchorReinforcementTensionDiameter?.getTrackingValue(this.localization) ?? '',
            'ReinforcementShear': this.anchorReinforcementShearDiameter?.getTrackingValue(this.localization) ?? '',
            'ReinforcementLongitudinal': this.anchorReinforcementLongitudinalDiameter?.getTrackingValue(this.localization) ?? '',
            'AnchorChannelFamilyGroup': this.anchorChannelFamilyGroup?.displayKey ?? '',
            'AnchorChannelType': this.anchorChannelFamily?.displayKey ?? '',
            'AnchorChannelEmbedmentDepth': this.getAnchorChannelEmbedmentDepthDisplayName,
            'BoltType': this.boltFamily?.displayKey ?? '',
            'BoltSize': this.boltSize?.displayKey ?? '',
            'BoltLength': this.boltLength?.displayKey ?? '',
            'Tolerance': this.loadTolerance?.displayKey ?? '',
            'ToleranceY': this.loadToleranceY?.displayKey ?? '',
            'ToleranceZ': this.loadToleranceZ?.displayKey ?? '',
            'InstallationConditionsDrillingMethod': this.installationConditionsDrillingMethods?.displayKey ?? '',
            'InstallationConditionsInstallationDirection': this.installationConditionsInstallationDirection?.displayKey ?? '',
            'InstallationConditionsHoleCondition': this.installationConditionsHoleCondition?.displayKey ?? '',
            'InstallationConditionsHoleType': this.installationConditionsHoleType?.displayKey ?? '',
            'ProductFamily': this.anchorFamily?.displayKey ?? '',
            'ProductType': this.anchorType?.displayKey ?? '',
            'ProductSize': this.anchorSize?.displayKey ?? '',
            'ApprovalNumber': this.approvalNumber,
            'AnchorChannelEmbedmentDepthType': this.anchorChannelEmbedmentDepthTypeName,
            'AnchorChannelEmbedmentDepthValue': this.anchorChannelEmbedmentDepthValue,
            'NumberOfAnchors': this.getNumberOfAnchorsDisplayName,
        };
    }

    private get getBaseMaterialDisplayName() {
        const value = this.baseMaterial?.displayKey ?? '';
        return value == 'Agito.Hilti.CW.CodeList.BaseMaterialStructureEntity.Custom' ? 'Custom' : value;
    }

    private get getAnchorChannelEmbedmentDepthDisplayName() {
        const value = this.anchorChannelEmbedmentDepth?.displayKey ?? '';
        return value == 'Agito.Hilti.CW.AnchorChannel.UserDefinedItem' ? 'User defined' : value;
    }

    public get installationConditionsDrillingMethods() {
        const codeList = this.designData.designCodeLists[DesignCodeList.DrillingMethods] as DrillingMethods[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.InstallationConditions_CW_DrillingMethod.id]);
    }

    public get installationConditionsInstallationDirection() {
        const codeList = this.designData.designCodeLists[DesignCodeList.InstallationDirections] as InstallationDirections[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.InstallationConditions_CW_InstallationDirection.id]);
    }

    public get installationConditionsHoleCondition() {
        const codeList = this.designData.designCodeLists[DesignCodeList.HoleConditions] as HoleConditions[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.InstallationConditions_CW_HoleCondition.id]);
    }

    public get installationConditionsHoleType() {
        const codeList = this.designData.designCodeLists[DesignCodeList.HoleTypes] as HoleTypes[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.InstallationConditions_CW_HoleType.id]);
    }

    public get unitTemperatureName() {
        const codeList = this.commonCodeList.commonCodeLists[CommonCodeList.UnitTemperature];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.Option_UnitTemperature.id]);
    }

    public get anchorChannelEmbedmentDepthTypeName() {
        return this.model[PropertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthOptimizationType.id] as number == 1
            ? 'Optimized'
            : 'User defined';
    }

    public get anchorChannelEmbedmentDepthValue() {
        const value = this.designData.projectDesign.product.postInstalledAnchor.embedmentDepth;
        return value != null ? value.toFixed(2) : '';
    }

    private get getNumberOfAnchorsDisplayName() {
        const value = this.model[PropertyMetaData.Bolt_CW_NumberOfBolts.id];
        return value !== undefined ? (this.model[PropertyMetaData.Bolt_CW_NumberOfBolts.id] as number).toString(): '';
    }

    public updateProjectDesign(projectDesign: DesignEntity) {
        this.designData.projectDesign = projectDesign;
    }

    public updateDesignCodeList(codeList: ICodeLists) {
        this.designData.designCodeLists = codeList;
    }

    public updateReportData(reportData?: ReportData) {
        this.designData.reportData = reportData;
    }

    public updateProjectDesignOptions(options: ReportOptionsEntity) {
        // details
        this.designData.projectDesign.options.reportDialog.notes = options.notes ?? '';
        this.designData.projectDesign.options.reportDialog.fasteningPoint = options.fasteningPoint ?? '';

        // custom comments
        this.designData.projectDesign.options.reportDialog.noteLoadCaseResultingRebarForces = options.noteLoadCaseResultingAnchorForces ?? '';
        this.designData.projectDesign.options.reportDialog.noteTensionLoad = options.noteTensionLoad ?? '';
        this.designData.projectDesign.options.reportDialog.noteShearLoad = options.noteShearLoad ?? '';
        this.designData.projectDesign.options.reportDialog.noteCombinedTensionAndShearLoads = options.noteCombinedTensionAndShearLoad ?? '';
        this.designData.projectDesign.options.reportDialog.noteDisplacements = options.noteDisplacements ?? '';
        this.designData.projectDesign.options.reportDialog.noteInstallationData = options.noteInstallationData ?? '';

        // layout
        this.designData.projectDesign.options.reportDialog.reportTemplateId = options.reportTemplateId;
        this.designData.projectDesign.options.reportDialog.reportCompanyName = options.companyName;
        this.designData.projectDesign.options.reportDialog.reportAddress = options.address;
        this.designData.projectDesign.options.reportDialog.reportContactPerson = options.contactPerson;
        this.designData.projectDesign.options.reportDialog.reportPhoneNumber = options.phoneNumber;
        this.designData.projectDesign.options.reportDialog.reportEmail = options.email;
        this.designData.projectDesign.options.reportDialog.reportType = options.reportType;
        this.designData.projectDesign.options.reportDialog.reportLanguageId = options.reportLanguageId;
        this.designData.projectDesign.options.reportDialog.reportPaperSize = options.reportPaperSize;
        this.designData.projectDesign.options.reportDialog.firstPageNumber = options.firstPage;

        // trimble connect
        this.designData.projectDesign.options.reportDialog.trimbleConnectUpload = options.trimbleConnectUpload;
        this.designData.projectDesign.options.reportDialog.trimbleConnectLocation = options.trimbleConnectLocation;
        this.designData.projectDesign.options.reportDialog.trimbleConnectReportName = options.trimbleConnectReportName;
        this.designData.projectDesign.options.reportDialog.trimbleConnectFolderId = options.trimbleConnectFolderId;
    }

    public updateFromDocumentDesign(documentDesign: IBaseDesign) {
        this.id = documentDesign.id;
        this.createDate = documentDesign.createDate;
        this.designName = documentDesign.designName;
        this.projectId = documentDesign.projectId;
        this.projectName = documentDesign.projectName;
        this.changeDate = documentDesign.changeDate;
        this.locked = documentDesign.locked;
        this.lockedUser = documentDesign.lockedUser;
    }

    public saveState(stateChange?: StateChange) {
        const oldState = this.currentState;

        // clear old states
        const index = this.states.findIndex((state) => state === this.currentState);

        if (index >= 0 && index < this.states.length - 1) {
            this.states.splice(index + 1, this.states.length - (index + 1));
        }

        if (this.states.length >= MAX_STATES) {
            this.states.splice(0, this.states.length - MAX_STATES + 1);
        }

        const { numberDecimalSeparator, numberThousandsSeparator } = this.userSettings.getNumberSeparators();

        // save state
        this.currentState = {
            properties: this.properties,
            model: cloneDeep(this.model),
            projectDesign: cloneDeep(this.projectDesign),
            designCodelists: cloneDeep(this.designData.designCodeLists),
            reportData: this.reportData,
            calculationLanguage: this.calculationLanguage ?? this.localization.selectedLanguage,
            undoRedoActions: this.pendingUndoRedoActions,
            decimalSeparator: numberDecimalSeparator,
            groupSeparator: numberThousandsSeparator,
            isReadOnlyDesign: this.isReadOnlyDesign,
        };

        this.states.push(this.currentState);

        this.pendingUndoRedoActions = [];

        // trigger state changed event
        this.trigger(DesignEvent.stateChanged, this, this.currentState, oldState, stateChange);
    }

    public get metaData(): DesignExternalMetaData {
        return {
            id: this.id,
            name: this.designName,
            region: this.region?.id,
            standard: this.designStandard?.id,
            designType: DesignType.CurtainWall,
            productName: this.productName,
            approvalNumber: this.approvalNumber
        } as DesignExternalMetaData;
    }

    public reloadState() {
        this.loadState(this.states.findIndex((state) => state === this.currentState));
    }

    public loadState(
        index: number,
    ) {
        const oldState = this.currentState;
        const oldIndex = this.states.findIndex(state => state === this.currentState);
        this.currentState = this.states[index];

        let updatedAllowedValues: number[] = [];
        let updatedHidden: number[] = [];
        let updatedDisabled: number[] = [];

        // update dependent state data on decimal an group separators
        //updateStateModelToCurrentSeparator(unitService, localizationService, userSettingsService, oldState);
        //updateStateModelToCurrentSeparator(unitService, localizationService, userSettingsService, design.currentState);

        this.changeModel(() => {

            if (this.currentState != null) {

                if (this.currentState.projectDesign != null)
                    this.updateProjectDesign(this.currentState.projectDesign);

                this.updateReportData(this.currentState.reportData);

                if (this.currentState.designCodelists != null)
                    this.updateDesignCodeList(this.currentState.designCodelists);

                this.model = cloneDeep(this.currentState.model);

                const update = this.updateProperties(this.currentState.properties, true);
                updatedAllowedValues = update.updatedAllowedValues;
                updatedHidden = update.updatedHidden;
                updatedDisabled = update.updatedDisabled;

                this.updateIsReadOnlyDesign(this.currentState.isReadOnlyDesign);
                this.calculationLanguage = this.currentState.calculationLanguage;
                this.runUndoRedoActions(oldIndex, index);
            }
        });


        if (updatedAllowedValues != null && updatedAllowedValues.length > 0) {
            this.trigger(DesignEvent.allowedValuesChanged, this, updatedAllowedValues);
        }

        if (updatedHidden != null && updatedHidden.length > 0) {
            this.trigger(DesignEvent.hiddenChanged, this);
        }

        if (updatedDisabled != null && updatedDisabled.length > 0) {
            this.trigger(DesignEvent.disabledChanged, this);
        }

        this.modelChanges.clear();

        // trigger state changed event
        this.trigger(DesignEvent.stateChanged, this, this.currentState, oldState);
    }

    private runUndoRedoActions(oldIndex: number, newIndex: number) {
        if (oldIndex == newIndex) {
            // run pending undo actions
            for (const action of this.pendingUndoRedoActions) {
                action.undo();
            }

            this.pendingUndoRedoActions = [];
        }
        else if (newIndex < oldIndex) {
            // undo
            for (let currentIndex = oldIndex; currentIndex > newIndex; currentIndex--) {
                const state = this.states[currentIndex];

                for (const action of state.undoRedoActions) {
                    action.undo();
                }
            }
        }
        else {
            // redo
            for (let currentIndex = oldIndex + 1; currentIndex <= newIndex; currentIndex++) {
                const state = this.states[currentIndex];

                for (const action of state.undoRedoActions) {
                    action.redo();
                }
            }
        }
    }

    public get canUndo() {
        return this.states.findIndex((state) => state === this.currentState) > 0;
    }

    public get canRedo() {
        const index = this.states.findIndex((state) => state === this.currentState);

        return index >= 0 && index < this.states.length - 1;
    }

    public changeModel(fn: (model: { [property: number]: unknown }) => void) {
        let currentModel = this.model;
        const oldModel = cloneDeep(this.model);
        let changes: { [property: string]: Change };

        try {
            const updatedModel = fn(this.model);
            if (updatedModel != null)
                currentModel = updatedModel;
        }
        finally {
            changes = this.changes.getShallowChanges(oldModel, currentModel);

            // set track changes values
            if (Object.keys(changes).length > 0) {
                for (const propertyId in changes) {
                    this.modelChanges.setOriginalProperty(propertyId, cloneDeep(changes[propertyId].newValue));
                }
            }
        }

        return changes;
    }

    public async processDesignClose(ignoreErrors: boolean): Promise<void> {
        if (this.pendingCloseDesign) {
            return;
        }

        this.pendingCloseDesign = true;

        // cancel all calculations
        this.resolveCalculation();

        const licensePromise = this.user.releaseAllFloatingLicenses(ignoreErrors);

        const trackingPromise = (this.isTemplate
            ? this.calculationService.trackOnTemplateClose(this)
            : this.calculationService.trackOnDesignClose(this)
        ).then(() => this.usageCounter.resetCounter());

        await Promise.all([licensePromise, trackingPromise]);
    }

    public processDesignBrowserUnload(): Promise<void> {
        // cancel all calculations
        this.resolveCalculation();

        let trackingPromise: Promise<void> = Promise.resolve();

        if (!this.isTemplate) {
            trackingPromise = this.calculationService.trackOnDesignClose(this, true);
        }

        return trackingPromise;
    }

    public resolveCalculation() {
        this.loading = false;
        this.setPendingCalculation(false);

        this.calculateDefer.resolve({ design: this, calculationCanceled: true, messagesClosed: Promise.resolve(), reportData: null });
        this.calculateDefer = new Deferred<ICalculationResult>();

        this.cancelCalculationRequest();
    }

    public cancelCalculationRequest(): boolean {
        if (this.cancellationToken != null) {
            this.cancellationToken.resolve();
            this.cancellationToken = undefined;

            // get the canceled changes back
            if (this.lastModelChanges != null) {
                this.modelChanges.changes = this.lastModelChanges.concat(this.modelChanges.changes);
                this.lastModelChanges = undefined;

                this.modelChanges.observe();
            }

            this.logger.log('Calculate canceled');

            return true;
        }

        return false;
    }

    public triggerDesignEvent(propertyChanges: IPropertyChanges) {
        if (propertyChanges.updatedAllowedValues != null && propertyChanges.updatedAllowedValues.length > 0) {
            this.trigger(DesignEvent.allowedValuesChanged, this, propertyChanges.updatedAllowedValues);
        }

        if (propertyChanges.updatedDisabledValues != null && propertyChanges.updatedDisabledValues.length > 0) {
            this.trigger(DesignEvent.disabledValuesChanged, this, propertyChanges.updatedDisabledValues);
        }

        if (Object.keys(propertyChanges.updatedHidden).length > 0) {
            this.trigger(DesignEvent.hiddenChanged, this);
        }

        if (Object.keys(propertyChanges.updatedDisabled).length > 0) {
            this.trigger(DesignEvent.disabledChanged, this);
        }
    }

    public get selectedAnchoringSystemId(): string {
        return this.projectDesign?.selectedAnchoringSystemId;
    }

    public get selectedAnchoringSystem(): AnchoringSystem {
        return this.projectDesign?.anchoringSystems.find(p => p.id == this.selectedAnchoringSystemId) ?? {} as AnchoringSystem;
    }

    public get selectedBasePlateSystemId(): string {
        return this.getSelectedBasePlateSystemId(this.selectedAnchoringSystemId);
    }

    public get selectedProductId() {
        return this.model[PropertyMetaData.AnchorChannel_CW_Family.id] as number;
    }

    public get newAndImportLoadDisabled() {
        return this.model[PropertyMetaData.Loads_CW_NewAndImportLoadDisabled.id] as boolean;
    }

    public isPostInstallAnchorProduct() {
        return this.selectedProductId < 0;
    }

    public get baseMaterial() {
        const codeList = this.designData.designCodeLists[DesignCodeList.BaseMaterials] as BaseMaterials[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.BaseMaterial_CW_Material.id]);
    }

    private getGenericRebarById(propertyMetaDataId: number) {
        const codeList = this.designData.designCodeLists[DesignCodeList.GenericRebars] as GenericRebars[];
        return codeList?.find((item) => item.id == this.model[propertyMetaDataId]);
    }

    private getBondConditionById(propertyMetaDataId: number) {
        const codeList = this.designData.designCodeLists[DesignCodeList.BondConditions] as BondConditions[];
        return codeList?.find((item) => item.id == this.model[propertyMetaDataId]);
    }

    public get anchorReinforcementTensionDiameter() {
        return this.getGenericRebarById(PropertyMetaData.AnchorReinforcement_CW_Tension_RebarId.id);
    }

    public get anchorReinforcementTensionBondCondition() {
        return this.getBondConditionById(PropertyMetaData.AnchorReinforcement_CW_Tension_BondCondition.id);
    }

    public get anchorReinforcementTensionConcreteCover() {
        return this.model[PropertyMetaData.AnchorReinforcement_CW_Tension_ConcreteCover.id] as number;
    }

    public get anchorReinforcementShearDiameter() {
        return this.getGenericRebarById(PropertyMetaData.AnchorReinforcement_CW_Shear_RebarId.id);
    }

    public get anchorReinforcementShearBondCondition() {
        return this.getBondConditionById(PropertyMetaData.AnchorReinforcement_CW_Shear_BondCondition.id);
    }

    public get anchorReinforcementShearConcreteCover() {
        return this.model[PropertyMetaData.AnchorReinforcement_CW_Shear_ConcreteCover.id] as number;
    }

    public get anchorReinforcementLongitudinalDiameter() {
        return this.getGenericRebarById(PropertyMetaData.AnchorReinforcement_CW_Longitudinal_RebarId.id);
    }

    public get anchorReinforcementLongitudinalBondCondition() {
        return this.getBondConditionById(PropertyMetaData.AnchorReinforcement_CW_Longitudinal_BondCondition.id);
    }

    public get anchorReinforcementLongitudinalConcreteCover() {
        return this.model[PropertyMetaData.AnchorReinforcement_CW_Longitudinal_ConcreteCover.id] as number;
    }

    public get anchorFamily() {
        const codeList = this.designData.designCodeLists[DesignCodeList.AnchorFamilies] as AnchorFamily[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.AnchorChannel_CW_Family.id]);
    }

    public get anchorType() {
        const codeList = this.designData.designCodeLists[DesignCodeList.AnchorTypes] as AnchorType[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.PostInstalledAnchor_CW_Type.id]);
    }

    public get anchorSize() {
        const codeList = this.designData.designCodeLists[DesignCodeList.AnchorSizes] as AnchorSize[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.PostInstalledAnchor_CW_Size.id]);
    }

    public get anchorChannelFamilyGroup() {
        const codeList = this.designData.designCodeLists[DesignCodeList.AnchorChannelFamilyGroup] as AnchorChannelFamilyGroup[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.AnchorChannel_CW_Family.id]);
    }

    public get anchorChannelFamily() {
        const codeList = this.designData.designCodeLists[DesignCodeList.AnchorChannelFamily] as AnchorChannelFamily[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.AnchorChannel_CW_Type.id]);
    }

    public get anchorChannelLength() {
        const codeList = this.designData.designCodeLists[DesignCodeList.AnchorChannelLength] as AnchorChannelLength[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.AnchorChannel_CW_Length.id]);
    }

    public get anchorChannelEmbedmentDepth() {
        const codeList = this.designData.designCodeLists[DesignCodeList.AnchorChannelEmbedmentDepth] as AnchorChannelEmbedmentDepth[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.AnchorChannel_CW_EmbedmentDepth.id]);
    }

    public get rebarPlate() {
        const codeList = this.designData.designCodeLists[DesignCodeList.RebarPlate] as RebarPlate[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.AnchorChannel_CW_RebarPlate.id]);
    }

    public get lipStrengthClip() {
        const codeList = this.designData.designCodeLists[DesignCodeList.LipStrengthClips] as LipStrengthClips[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.AnchorChannel_CW_LipStrengthClip.id]);
    }

    public get selectedBoltId() {
        return this.model[PropertyMetaData.Bolt_CW_Type.id] as number;
    }

    public get anchorReinforcementYieldStrength() {
        return this.model[PropertyMetaData.AnchorReinforcement_CW_YieldStrength.id] as number;
    }

    public get anchorReinforcementConcreteCover() {
        return this.model[PropertyMetaData.AnchorReinforcement_CW_ConcreteCover.id] as number;
    }

    public get anchorReinforcementTensionEnabled() {
        return this.model[PropertyMetaData.AnchorReinforcement_CW_Tension_IsEnabled.id] as boolean;
    }

    public get anchorReinforcementLongitudinalEnabled() {
        return this.model[PropertyMetaData.AnchorReinforcement_CW_Longitudinal_IsEnabled.id] as boolean;
    }

    public get anchorReinforcementShearEnabled() {
        return this.model[PropertyMetaData.AnchorReinforcement_CW_Shear_IsEnabled.id] as boolean;
    }

    public get boltFamily() {
        const codeList = this.designData.designCodeLists[DesignCodeList.BoltFamilies] as BoltFamily[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.Bolt_CW_Type.id]);
    }

    public get boltSize() {
        const codeList = this.designData.designCodeLists[DesignCodeList.BoltSizes] as BoltSize[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.Bolt_CW_Size.id]);
    }

    public get boltLength() {
        const codeList = this.designData.designCodeLists[DesignCodeList.BoltLengths] as BoltLength[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.Bolt_CW_Length.id]);
    }

    public get loadTolerance() {
        const codeList = this.designData.designCodeLists[DesignCodeList.LoadTolerances] as LoadTolerances[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.Loads_CW_Tolerance_Type.id]);
    }

    public get loadToleranceY() {
        const codeList = this.designData.designCodeLists[DesignCodeList.LoadTolerancesY] as LoadTolerances[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.Loads_CW_ToleranceY_Type.id]);
    }

    public get loadToleranceZ() {
        const codeList = this.designData.designCodeLists[DesignCodeList.LoadTolerancesZ] as LoadTolerances[];
        return codeList?.find((item) => item.id == this.model[PropertyMetaData.Loads_CW_ToleranceZ_Type.id]);
    }

    public get bolt() {
        return this.designData.projectDesign?.basePlate.bolt;
    }

    public get designMethodGroup() {
        const codeList = this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroups] as DesignMethodGroup[];
        return codeList.find((designMethodGroup) => designMethodGroup.id == this.model[PropertyMetaData.Option_CW_DesignMethodGroup.id]);
    }
    public set designMethodGroup(value) {
        this.model[PropertyMetaData.Option_CW_DesignMethodGroup.id] = value != null ? value.id : undefined;
    }

    public get designStandard() {
        const codeList = this.codeList.projectCodeLists[ProjectCodeList.DesignStandards] as DesignStandard[];
        return codeList.find((d) => d.id == this.model[PropertyMetaData.Option_CW_DesignStandard.id]);
    }

    public set designStandard(value) {
        this.model[PropertyMetaData.Option_CW_DesignStandard.id] = value != null ? value.id : null;
    }

    public get basePlateSize() {
        return this.model[PropertyMetaData.Plate_CW_Size.id] as BasePlateEntity;
    }
    public set basePlateSize(value) {
        this.model[PropertyMetaData.Plate_CW_Size.id] = value;
    }

    public get unitLength() {
        return this.model[PropertyMetaData.Option_UnitLength.id] as Unit;
    }
    public set unitLength(value) {
        this.model[PropertyMetaData.Option_UnitLength.id] = value != null ? value : null;
    }

    get unitLengthLarge(): Unit {
        throw new Error('Method not implemented (unitLengthLarge not needed in cw).');
    }

    public get unitArea() {
        return this.model[PropertyMetaData.Option_UnitArea.id] as Unit;
    }
    public set unitArea(value) {
        this.model[PropertyMetaData.Option_UnitArea.id] = value != null ? value : null;
    }

    public get unitStress() {
        return this.model[PropertyMetaData.Option_UnitStress.id] as Unit;
    }
    public set unitStress(value) {
        this.model[PropertyMetaData.Option_UnitStress.id] = value != null ? value : null;
    }

    public get unitStressSmall(): Unit {
        throw new Error('Method not implemented (unitStressSmall not needed in cw).');
    }

    public get unitForce() {
        return this.model[PropertyMetaData.Option_UnitForce.id] as Unit;
    }
    public set unitForce(value) {
        this.model[PropertyMetaData.Option_UnitForce.id] = value != null ? value : null;
    }

    public get unitMoment() {
        return this.model[PropertyMetaData.Option_UnitMoment.id] as Unit;
    }
    public set unitMoment(value) {
        this.model[PropertyMetaData.Option_UnitMoment.id] = value != null ? value : null;
    }

    public get unitTemperature() {
        return this.model[PropertyMetaData.Option_UnitTemperature.id] as Unit;
    }
    public set unitTemperature(value) {
        this.model[PropertyMetaData.Option_UnitTemperature.id] = value != null ? value : null;
    }

    public get unitForcePerLength() {
        return this.model[PropertyMetaData.Option_UnitForcePerLength.id] as Unit;
    }
    public set unitForcePerLength(value) {
        this.model[PropertyMetaData.Option_UnitForcePerLength.id] = value != null ? value : null;
    }

    public get unitMomentPerLength() {
        return this.model[PropertyMetaData.Option_UnitMomentPerLength.id] as Unit;
    }
    public set unitMomentPerLength(value) {
        this.model[PropertyMetaData.Option_UnitMomentPerLength.id] = value != null ? value : null;
    }

    public get unitDensity() {
        return this.model[PropertyMetaData.Option_UnitDensity.id] as Unit;
    }
    public set unitDensity(value) {
        this.model[PropertyMetaData.Option_UnitDensity.id] = value != null ? value : null;
    }

    public get unitAreaPerLength() {
        return this.model[PropertyMetaData.Option_UnitAreaPerLength.id] as Unit;
    }
    public set unitAreaPerLength(value) {
        this.model[PropertyMetaData.Option_UnitAreaPerLength.id] = value != null ? value : null;
    }

    public get basePlateFactor() {
        return this.model[PropertyMetaData.Option_CW_BasePlateFactor.id] as number | undefined;
    }
    public set basePlateFactor(value) {
        this.model[PropertyMetaData.Option_CW_BasePlateFactor.id] = value != null ? value : null;
    }

    public get safetyFactorPermLoad() {
        return this.model[PropertyMetaData.Option_CW_SafetyFactorPermanentLoad.id] as number | undefined;
    }
    public set safetyFactorPermLoad(value) {
        this.model[PropertyMetaData.Option_CW_SafetyFactorPermanentLoad.id] = value != null ? value : null;
    }

    public get safetyFactorVarLoad() {
        return this.model[PropertyMetaData.Option_CW_SafetyFactorVariableLoad.id] as number | undefined;
    }
    public set safetyFactorVarLoad(value) {
        this.model[PropertyMetaData.Option_CW_SafetyFactorVariableLoad.id] = value != null ? value : null;
    }

    public get minAnchorProfileDist() {
        return this.model[PropertyMetaData.Option_CW_MinAnchorProfileDist.id] as number | undefined;
    }
    public set minAnchorProfileDist(value) {
        this.model[PropertyMetaData.Option_CW_MinAnchorProfileDist.id] = value != null ? value : null;
    }

    public get minConcreteCover() {
        return this.model[PropertyMetaData.Option_CW_MinConcreteCover.id] as number | undefined;
    }
    public set minConcreteCover(value) {
        this.model[PropertyMetaData.Option_CW_MinConcreteCover.id] = value != null ? value : null;
    }

    public get concreteSafetyFactorGammaC() {
        return this.model[PropertyMetaData.Option_CW_ConcreteSafetyFactorGammaC.id] as ConcreteSafetyFactorGammaC | undefined;
    }
    public set concreteSafetyFactorGammaC(value) {
        this.model[PropertyMetaData.Option_CW_ConcreteSafetyFactorGammaC.id] = value != null ? value : null;
    }

    public get loads(): LoadCombinationEntity[] {
        return this.designData?.projectDesign?.loads.loadCombinations;
    }

    public set loads(value: LoadCombinationEntity[]) {
        this.designData.projectDesign.loads.loadCombinations = value;
    }

    public get decisiveLoadCombination() {
        return this.designData?.projectDesign?.loads.decisiveLoadCombinationId;
    }

    public get isMultipleBrackets(): boolean {
        return this.selectedAnchoringSystem.basePlateSystems.length > 1;
    }

    public get isPerBolt(): boolean {
        return this.designData.projectDesign.basePlate.plateShape == PlateShapes.NoPlate;
    }

    public get isFactoredSustainedLoadsActive() {
        return this.model[PropertyMetaData.Loads_CW_AreFactoredSustainedLoadsActive.id] as boolean;
    }

    public get isAnchorChannelWithXtProductFamily(): boolean {
        const anchorChannel = this.designData.projectDesign.product.anchorChannel;
        return anchorChannel?.channelType == AnchorChannelTypes.Rebar && anchorChannel?.rebarPlateId == 0;
    }

    public get isMultipleLoadCombinations(): boolean {
        return !this.isPerBolt && !this.isMultipleBrackets && this.designData.projectDesign?.loads.loadCombinations.length > 1 && !this.newAndImportLoadDisabled;
    }

    public get loadType() {
        return this.model[PropertyMetaData.Load_CW_LoadType.id] as LoadTypes;
    }
    public set loadType(value) {
        this.model[PropertyMetaData.Load_CW_LoadType.id] = value;
    }

    public get loadCombinationLoadType() {
        return this.model[PropertyMetaData.LoadCombination_CW_LoadType.id] as {key: string; value: LoadTypes};
    }
    public set loadCombinationLoadType(value) {
        this.model[PropertyMetaData.LoadCombination_CW_LoadType.id] = value;
    }

    public get selectedLoadCombinationId() {
        return this.model[PropertyMetaData.Loads_CW_SelectedLoadCombinationId.id] as string;
    }
    public set selectedLoadCombinationId(value) {
        this.model[PropertyMetaData.Loads_CW_SelectedLoadCombinationId.id] = value;
    }

    public get designSubType(): DesignSubTypes | undefined {
        return this.designData?.projectDesign?.options.designSubType;
    }

    public get hasUtilizations() {
        const generalUtilizations = this.reportData?.utilizationsData[this.selectedLoadCombinationId]?.generalUtilizations;
        return generalUtilizations && generalUtilizations.length > 0;
    }

    public get drillingMethod() {
        // Get drilling method from design data and not from model (backend is not setting drilling method properties in case channel is selected)
        return this.designData.projectDesign.installationConditions.drillingMethod;
    }
    public set drillingMethod(value) {
        // Set drilling method from design data and not from model (backend is not able to set drilling method in case channel is selected - we do not have CW property updater)
        this.designData.projectDesign.installationConditions.drillingMethod = value;
    }

    public get holeType() {
        // Get hole type from design data and not from model (backend is not setting hole type properties in case channel is selected)
        return this.designData.projectDesign.installationConditions.holeType;
    }
    public set holeType(value) {
        // Set hole type from design data and not from model (backend is not able to set hole type in case channel is selected - we do not have CW property updater)
        this.designData.projectDesign.installationConditions.holeType = value;
    }

    public get torquingType() {
        return this.model[PropertyMetaData.InstallationConditions_AdaptiveTorquing.id]
            ? TorquingTypes.Automatic
            : TorquingTypes.Manual;
    }
    public set torquingType(value) {
        this.model[PropertyMetaData.InstallationConditions_AdaptiveTorquing.id] = value == TorquingTypes.Automatic;
    }

    public get designMethodName() {
        const designMethod = this.reportData?.designMethodName;

        if (designMethod && !this.isPostInstallAnchorProduct()) {
            const noMethod = this.localization.getString('Agito.Hilti.CW.DesignReportData.NoMethod');
            return this.hasUtilizations ? this.localization.getString(`Agito.Hilti.CW.DesignMethod.${designMethod}`) : noMethod;
        }

        return designMethod || this.localization.getString('Agito.Hilti.CW.DesignReportData.NoMethod');
    }

    public get longitudinalShearCalculationType() {
        const lscTypeId = this.model[PropertyMetaData.Loads_CW_LongitudinalShearCalculation.id] as LongitudinalShearCalculationTypes;

        const lscType = lscTypeId == LongitudinalShearCalculationTypes.CEN_TR_17080 ? 'CEN_TR_17080' : 'HiltiMethod';
        return this.localization.getString(`Agito.Hilti.CW.CodeList.LongitudinalShearCalculationEntity.${lscType}`);
    }

    public get approvalNumber() {
        const approvals = this.model[PropertyMetaData.AnchorChannel_CW_Approvals.id] as ApprovalsEntity[];

        if (!this.hasUtilizations || !approvals) {
            return '';
        }

        const defaultApprovalNumber = this.localization.getString('Agito.Hilti.CW.DesignReportData.HiltiTechnicalData');
        const reportApprovalNumber = this.reportData?.approvals?.[0]?.apprNumber ?? defaultApprovalNumber;
        const approvalNumber = this.isPostInstallAnchorProduct() ? reportApprovalNumber : approvals[0]?.apprNumber ?? defaultApprovalNumber;

        return approvalNumber;
    }

    public get applicationType() {
        return this.model[PropertyMetaData.Option_CW_ApplicationType.id] as ApplicationTypes;
    }

    public get fasteningTechnologies() {
        return this.model[PropertyMetaData.Application_CW_FasteningTechnology.id] as FasteningTechnologies[];
    }

    public get loadCombinationWizard() {
        return this.model[PropertyMetaData.Loads_CW_Wizard.id] as LoadCombinationWizardEntity;
    }

    public set loadCombinationWizard(value) {
        this.model[PropertyMetaData.Loads_CW_Wizard.id] = value;
    }

    public getAvailableRegions() {
        return this.codeList.designType?.regions ?? [];
    }

    public getSelectedBasePlateSystemId(anchoringSystemId: string) {
        return this.projectDesign?.anchoringSystems.find(p => p.id == anchoringSystemId)?.selectedBasePlateSystemId ?? '';
    }

    public setSelectedBasePlateSystemId(value: string) {
        this.projectDesign.anchoringSystems.forEach((anchoringSystem) => {
            anchoringSystem.selectedBasePlateSystemId = value;
        });
    }

    public get isPostInstalledAnchorSelected(): boolean {
        return this.projectDesign?.product.anchorChannel.familyGroupId < 0;
    }

    public get loadsCalculationMode() {
        return this.model[PropertyMetaData.Loads_CW_LoadCombinationsCalculateType.id] as LoadCombinationsCalculateTypes;
    }

    public set loadsCalculationMode(value) {
        this.model[PropertyMetaData.Loads_CW_LoadCombinationsCalculateType.id] = value;
    }

    public get customPictures() {
        return this.model[PropertyMetaData.Option_CW_CustomPictures.id] as string[];
    }
    public set customPictures(val: string[]) {
        this.model[PropertyMetaData.Option_CW_CustomPictures.id] = val;
    }

    public set deleteLoads(value: string[]) {
        this.model[PropertyMetaData.Loads_CW_DeleteLoadCombinationsByIds.id] = value;
    }

    public get loadsLegendType(): LoadsLegendType {
        const hasSustainedLoads = this.projectDesign?.loads.loadCombinations.some((combination) => combination.hasSustainedLoads);
        if (hasSustainedLoads) {
            return LoadsLegendType.Sustained;
        }

        // TODO: Uncomment this code to show the legend - CW-3554 task
        const hasCharacteristicLoads = false; //this.projectDesign?.loads.loadCombinations.some((combination) => combination.hasCharacteristicLoads);
        if (hasCharacteristicLoads) {
            return LoadsLegendType.Characteristic;
        }

        return LoadsLegendType.None;
    }

    public get showFullPortfolioLinks(): AvailabilityRegionLinks | undefined{
        const codeList = this.designData.designCodeLists[DesignCodeList.AvailabilityRegionLinks] as AvailabilityRegionLinks[];
        return codeList?.find((item) => item.regionId == this.model[PropertyMetaData.Option_RegionId.id]);
    }

    public dispose() {
        CalculationServiceBase.cancelCalculationRequest(this, this.logger);

        this.calculateDefer.promise.catch(() => { /*Nothing to do*/ });
        this.calculateDefer.reject();
        this.calculateDefer = new Deferred<ICalculationResult>();

        this.off(DesignEvent.stateChanged, this.stateChanged);
    }

    get currentStateIdx(): number {
        throw new Error('Method not implemented.');
    }

    get isStateChanged(): boolean {
        throw new Error('Method not implemented.');
    }

    setSavedStateIdx(): void {
        // Do nothing
    }

    override addModelChange(propertyId: number, noCollapse?: boolean | undefined, value?: unknown, oldValue?: unknown) {
        this.addModelChangeInternal(propertyId, noCollapse, value as object, oldValue as object);

        return this.calculationService.calculateAsync(this);
    }
}

