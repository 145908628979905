/* eslint-disable @typescript-eslint/no-unused-vars */

import { Injectable } from '@angular/core';
import { ILocalizationExtension } from '@profis-engineering/pe-ui-common/services/extensions.common';

import {
    MenuServiceInjected
} from '@profis-engineering/pe-ui-common/services/menu.common';
import { Design } from '../entities/design';
import { CalculationService } from './calculation.service';
import { CodeListService } from './code-list.service';
import { FavoritesService } from './favorites.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { MenuServiceExtensions } from './menu-service-extensions';
import { ModalService } from './modal.service';
import { NumberService } from './number.service';
import { StaticMenuService } from './static-menu.service';
import { TranslationFormatService } from './translation-format.service';
import { UnitService } from './unit.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService extends MenuServiceInjected<Design> {

    constructor(
        private codeListService: CodeListService,
        private staticMenuService: StaticMenuService,
        private localizationService: LocalizationService,
        private userSettingsService: UserSettingsService,
        private calculationService: CalculationService,
        private userService: UserService,
        private loggerService: LoggerService,
        private unitService: UnitService,
        private numberService: NumberService,
        private modalService: ModalService,
        private translationFormatService: TranslationFormatService,
        private favoritesService: FavoritesService
    ) {
        super();
    }

    public initialize() {
        this.setMenuExtensions(new MenuServiceExtensions(
            this.codeListService,
            this.staticMenuService,
            this.localizationService,
            this.userSettingsService,
            this.calculationService,
            this.userService,
            this.loggerService,
            this.unitService,
            this.numberService,
            this.modalService,
            this.translationFormatService,
            this.favoritesService,
        ));

        this.menuExtensions.localizationExtension = {
            getTranslation: this.localizationService.getString.bind(this.localizationService),
            checkForTranslation: this.localizationService.hasTranslation.bind(this.localizationService)
        } as ILocalizationExtension;
    }
}
